import OutputResource from "./resource";

export enum OutputStructureGroup {
  "committee" = "committee",
  "division" = "division",
  "project" = "project",
  "member-full" = "member-full",
  "member-obs" = "member-obs",
}

class OutputStructure {
  public readonly logoSimpleWhite?: string;
  public readonly logoSimpleColor?: string;

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly nameShort: string,
    public readonly url: string | undefined | null,
    logoSimpleWhite: string | undefined | null,
    logoSimpleColor: string | undefined | null,
    imageHost: string,
    public readonly logoText: OutputResource,
    public readonly color: number,
    public readonly group: OutputStructureGroup
  ) {
    this.logoSimpleWhite =
      logoSimpleWhite === undefined ||
      logoSimpleWhite === null ||
      logoSimpleWhite === "null"
        ? undefined
        : imageHost + logoSimpleWhite;
    this.logoSimpleColor =
      logoSimpleColor === undefined ||
      logoSimpleColor === null ||
      logoSimpleColor === "null"
        ? undefined
        : imageHost + logoSimpleColor;
  }
}

export default OutputStructure;
