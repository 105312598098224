import { faFacebook, faInstagram } from "@fortawesome/fontawesome-free-brands";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNewsDetail } from "../../../service/news/get";
import {
  useLoaderData,
  useRouteError,
  useNavigate,
  redirect,
} from "react-router-dom";
import ResourceDisplay from "../../../components/resource-display";
import NewsSummaryCard from "../../../components/news-summary-card/index";

import { default as Markdown } from "react-remarkable";
import GenericErrorDisplay from "../../generic-error";
import { AxiosError } from "axios";

import React, { useEffect, useState } from "react";
import { getResources } from "../../../service/resource/index";
import { IMAGE_HOST } from "../../../api/config";

const paramName = "name";
export const path = ":" + paramName + "/";

const resourceKeys = {
  backgroundImage: "image-paper-white",
  relatedTitle: "news-detail-related-title",
};

export async function loader({ request, params }) {
  const resources = getResources(Object.values(resourceKeys));

  try {
    const name = params[paramName];
    const data = await getNewsDetail(name);
    return [data, await resources];
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.response &&
      error.response.status === 404
    ) {
      return redirect("/not-found");
    }
  }
}

export function Component() {
  const [data, resourcesArray] = useLoaderData();

  const [backgroundImage, setBackgroundImage] = useState();
  const [relatedTitle, setRelatedTitle] = useState();

  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setBackgroundImage(null);
      setRelatedTitle(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.backgroundImage) {
        setBackgroundImage(resource);
        console.log(resource);
      }
      if (resource.id === resourceKeys.relatedTitle) setRelatedTitle(resource);
    });
  }, [resourcesArray]);

  return (
    <div id="pages-news-detail">
      <div className="header">
        <ResourceDisplay
          className="background-image"
          resource={data.back_img}
        ></ResourceDisplay>

        <div className="container content-container">
          <div className="text">
            <div className="stretcher" />
            <h3 className="title">{data.title}</h3>
            <div className="stretcher" />
            <div className="hashtag">
              {data.hashtags.map((elem) => (
                <div>#{elem}</div>
              ))}
            </div>
            <div className="date">
              <span className="bold">
                {data.date.toLocaleDateString("ro-RO", { dateStyle: "long" })}
              </span>
            </div>
            <div className="icons">
              {data.facebook !== "" && (
                <a href={data.facebook}>
                  <FontAwesomeIcon size="xl" icon={faFacebook} />
                </a>
              )}
              {data.instagram !== "" && (
                <a href={data.instagram}>
                  <FontAwesomeIcon size="xl" icon={faInstagram} />
                </a>
              )}
            </div>
          </div>
          <div className="foreground-image-container">
            <ResourceDisplay
              className="foreground-image"
              resource={data.front_img}
            ></ResourceDisplay>
          </div>
        </div>
      </div>
      <div
        className="body"
        style={
          backgroundImage
            ? {
                backgroundImage:
                  "url(" + IMAGE_HOST + backgroundImage.value + ")",
              }
            : {}
        }
      >
        <div className="maincontainer content-container">
          <div className="text">
            <Markdown source={data.content} />
          </div>
        </div>
        <ResourceDisplay
          resource={relatedTitle}
          className="nearfooter"
          wrapper="h3"
        />
        <div className="news-container content-container">
          {data.related.map((elem) => (
            <NewsSummaryCard newsSummary={elem}></NewsSummaryCard>
          ))}
        </div>
      </div>
    </div>
  );
}

Component.displayName = "page-noutate-detaliu";

export function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      error instanceof AxiosError &&
      error.response &&
      error.response.status === 404
    ) {
      // console.log("aici", error);
      navigate("/not-found", { replace: true });
    }
  }, [error, navigate]);

  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-noutate-detaliu-error";
