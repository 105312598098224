import { Outlet, Route, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../generic-error";
import OutputResource from "../../../../dtos/resource";
import ResourceService from "../../../../service/resource";
import { OutputExchangesContinentList } from "../../../../dtos/exchanges_out";
import ExchangesOutService from "../../../../service/exchanges_out";

import * as PageIndex from "./page-index";
import * as PageContinent from "./page-continent";

export const path = "out";

const resourceKeys = {
  headerWide: "opportunities-exchanges-world-map",
};

type LoaderType = [
  { [key: string]: OutputResource },
  OutputExchangesContinentList[]
];

export async function loader(): Promise<LoaderType> {
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  const continents = new ExchangesOutService().read.continents();
  return [await resources, await continents];
}

export function Component() {
  return (
    <div id="outgoings">
      <Outlet />
    </div>
  );
}

Component.displayName = "page-oportunitati-exchanges-outgoings";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-exchanges-outgoings-error";

export const children = [
  <Route index {...PageIndex} />,
  <Route {...PageContinent} />,
];
