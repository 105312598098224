import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from ".";
import TransmedApi from "../api/transmed";
import { OutputTransmedCounty, RequestCounties } from "../dtos/transmed/county";
import {
  OutputTransmedReview,
  RequestReviewsByCounty,
} from "../dtos/transmed/review";

class TransmedServiceReader extends ServiceReader {
  constructor(protected api: TransmedApi) {
    super();
  }

  public async counties(): Promise<OutputTransmedCounty[]> {
    const raw = await this.api.get(new RequestCounties());

    if (!Array.isArray(raw))
      throw new TypeError("Counties list is not an array.");

    return raw.map((value) => OutputTransmedCounty.convert(value));
  }

  public async byCounty(countyName: string): Promise<OutputTransmedReview[]> {
    const raw = await this.api.get(new RequestReviewsByCounty(countyName));

    if (!Array.isArray(raw))
      throw new TypeError("Reviews list is not an array.");

    return raw.map((value) => OutputTransmedReview.convert(value));
  }
}

class TransmedServiceCreator extends ServiceCreator {}
class TransmedServiceUpdater extends ServiceUpdater {}
class TransmedServiceDeleter extends ServiceDeleter {}

export default class TransmedService extends Service {
  constructor() {
    super(new TransmedApi());
  }

  public get read(): TransmedServiceReader {
    return new TransmedServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new TransmedServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new TransmedServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new TransmedServiceDeleter();
  }
}
