import { RequestResourcesByIds } from "../dtos/resource";
import Api from "./index";

export default class ResourceApi extends Api {
  constructor() {
    super("/index.php");
  }

  public get(params: RequestResourcesByIds): Promise<any> {
    return super.get(params);
  }
}
