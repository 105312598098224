import Api from "./index";

export default class ExchangesCardApi extends Api {
  constructor() {
    super("/exchanges_card.php");
  }

  public get(params?: undefined): Promise<any> {
    return super.get(null);
  }
}
