import OutputEntity, { DtoConversionError, RequestEntity } from "..";

export class OutputTransmedGalleryEntry extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly location: string,
    public readonly caption: string | undefined
  ) {
    super(id);
  }

  static convert(input: any): OutputTransmedGalleryEntry {
    const id = input.id ?? undefined;
    const location = input.location ?? undefined;
    const caption = input.caption ?? undefined;

    console.log(caption);

    if (typeof id !== "number")
      throw new OutputTransmedGalleryEntryConversionError("id", input);
    if (typeof location !== "string")
      throw new OutputTransmedGalleryEntryConversionError("location", input);
    if (typeof caption !== "string" && caption !== undefined)
      throw new OutputTransmedGalleryEntryConversionError("caption", input);

    return OutputTransmedGalleryEntryConversionError.propagate(
      OutputTransmedGalleryEntryConversionError.dtoName,
      () => new OutputTransmedGalleryEntry(id, location, caption)
    );
  }
}

class OutputTransmedGalleryEntryConversionError extends DtoConversionError {
  public static dtoName: string = "OutputTransmedGalleryEntry";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputTransmedGalleryEntryConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class RequestGalleryEntriesByCounty extends RequestEntity {
  constructor(public readonly county: string) {
    super();
  }
}
