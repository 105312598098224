import { useLoaderData, useNavigate, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../generic-error";
import { getResources } from "../../service/resource/index";
import ResourceDisplay from "../../components/resource-display";
import ButtonPrimary from "../../components/button-primary/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export const path = "/in-construction";

export async function loader({ request }) {
  return await getResources(["in-construction-image"]);
}

export function Component() {
  const [image] = useLoaderData();
  const navigate = useNavigate();

  return (
    <div id="in-construction">
      <div className="text-container">
        <h1>În lucru...</h1>
        <h4>Pagina va fi disponibilă în curând</h4>
        <ButtonPrimary onClick={() => navigate(-1)} className="button">
          <FontAwesomeIcon icon={faChevronLeft} />
          Înapoi
        </ButtonPrimary>
      </div>
      <ResourceDisplay resource={image} className="image-container" />
    </div>
  );
}
Component.displayName = "page-in-constructie";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-in-constructie-error";
