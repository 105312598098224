import {
  LoaderFunctionArgs,
  useLoaderData,
  useParams,
  useRouteError,
  useRouteLoaderData,
} from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../../generic-error";
import ExchangesInService from "../../../../../service/exchanges_in";
import ReviewDisplay, {
  ReviewDisplayReview,
} from "../../../../../components/review-display";
import ResourceDisplay from "../../../../../components/resource-display";
import ResourceService from "../../../../../service/resource";
import OutputResource from "../../../../../dtos/resource";
import {
  OutputExchangesInReview,
  RequestInReviewType,
} from "../../../../../dtos/exchanges_in";

import { LoaderType as InLoaderType } from "..";
import { id as InId } from "..";

const paramNames = { type: "type", county: "county" };

export const path = `:${paramNames.type}/:${paramNames.county}/`;

const resourceKeys = {
  aboutLinie: "about-nonews-liniealbastra",
  aboutPeople: "about-nonews-people",
};

type LoaderType = [
  OutputExchangesInReview[],
  { [key: string]: OutputResource }
];

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderType> {
  const reviews = new ExchangesInService().read.byCounty(
    params[paramNames.county]!,
    RequestInReviewType[
      params[paramNames.type]! as keyof typeof RequestInReviewType
    ]
  );
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  return [await reviews, await resources];
}

export function Component() {
  const [reviews, resources] = useLoaderData() as LoaderType;
  const [, counties] = useRouteLoaderData(InId) as InLoaderType;

  const params = useParams();

  return (
    <section className="list content-container">
      {counties
        .filter((county) => county.name === params[paramNames.county])
        .map((county) => (
          <>
            <h3 className="country-title">{county.title}</h3>
          </>
        ))}
      {reviews.length === 0 ? (
        <div className="content-container">
          <div className="no-reviews">
            <div className="big-container">
              <h2 className="text-container">No reviews available</h2>
              <div className="line-container">
                <ResourceDisplay
                  resource={resources[resourceKeys.aboutLinie]}
                />
              </div>
            </div>
            <div className="image-container">
              <ResourceDisplay resource={resources[resourceKeys.aboutPeople]} />
            </div>
          </div>
        </div>
      ) : (
        <>
          {reviews.map((review) => (
            <ReviewDisplay
              review={
                new ReviewDisplayReview(
                  review.name,
                  review.date.toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "long",
                  }),
                  review.title,
                  review.pros,
                  review.cons
                )
              }
            />
          ))}
        </>
      )}
    </section>
  );
}
Component.displayName =
  "page-oportunitati-exchanges-outgoings-continent-reviews";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName =
  "page-oportunitati-exchanges-outgoings-continent-reviews-error";
