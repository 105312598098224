import { RequestGalleryEntriesByStructure } from "../dtos/gallery";
import Api from "./index";

export default class GalleryApi extends Api {
  constructor() {
    super("/gallery.php");
  }

  public get(params: RequestGalleryEntriesByStructure): Promise<any> {
    return super.get(params);
  }
}
