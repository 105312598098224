import OutputPerson from "../../dtos/person";
import { getGroup } from "../../api/person/get";
import ServiceError from "../error";
import { IMAGE_HOST } from "../../api/config";

export function toOutputPerson(person: any): OutputPerson {
  if (person.id === undefined || person.id === null)
    throw new ServiceError("Invalid person", "Empty id.", person);

  if (person.name === undefined || person.name === null)
    throw new ServiceError(
      "Invalid person",
      `Empty name for person ${person.id}.`,
      person
    );

  if (person.position === undefined || person.position === null)
    throw new ServiceError(
      "Invalid person",
      `Empty position for person ${person.id}.`,
      person
    );

  if (person.email === undefined || person.email === null)
    throw new ServiceError(
      "Invalid person",
      `Empty email for person ${person.id}.`,
      person
    );

  if (person.profile === undefined || person.profile === null)
    throw new ServiceError(
      "Invalid person",
      `Empty profile location for person ${person.id}.`,
      person
    );

  return new OutputPerson(
    String(person.id),
    String(person.name),
    String(person.position),
    String(person.email),
    String(person.profile),
    IMAGE_HOST,
    String(person.description),
    person?.position_short
  );
}

export async function getPersonGroup(group: string): Promise<OutputPerson[]> {
  const rawData: any = await getGroup(group);
  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid person array",
      "Invalid person array.",
      rawData
    );

  return rawData.map((person) => toOutputPerson(person));
}
