import Api from "../api";

export abstract class ServiceReader {}
export abstract class ServiceCreator {}
export abstract class ServiceUpdater {}
export abstract class ServiceDeleter {}

export default abstract class Service {
  protected constructor(protected api: Api) {}

  public abstract get read(): ServiceReader;
  public abstract get create(): ServiceCreator;
  public abstract get update(): ServiceUpdater;
  public abstract get delete(): ServiceDeleter;
}