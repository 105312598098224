import OutputEntity, { DtoConversionError, RequestEntity } from "..";

export class OutputTransmedReview extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly pros: string,
    public readonly cons: string | undefined,
    public readonly date: Date
  ) {
    super(id);
  }

  static convert(input: any): OutputTransmedReview {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const title = input.title ?? undefined;
    const pros = input.pros ?? undefined;
    const cons = input.cons ?? undefined;
    const date = input.date ?? undefined;

    console.log(cons);

    if (typeof id !== "number")
      throw new OutputTransmedReviewConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputTransmedReviewConversionError("name", input);
    if (typeof title !== "string")
      throw new OutputTransmedReviewConversionError("title", input);
    if (typeof pros !== "string")
      throw new OutputTransmedReviewConversionError("pros", input);
    if (typeof cons !== "string" && cons !== undefined)
      throw new OutputTransmedReviewConversionError("cons", input);
    if (typeof date !== "string")
      throw new OutputTransmedReviewConversionError("date", input);

    return OutputTransmedReviewConversionError.propagate(
      OutputTransmedReviewConversionError.dtoName,
      () =>
        new OutputTransmedReview(id, name, title, pros, cons, new Date(date))
    );
  }
}

class OutputTransmedReviewConversionError extends DtoConversionError {
  public static dtoName: string = "OutputTransmedReview";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputTransmedReviewConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class RequestReviewsByCounty extends RequestEntity {
  public readonly reviews = null;
  constructor(public readonly county: string) {
    super();
  }
}
