import {
  LoaderFunctionArgs,
  Outlet,
  Route,
  useLoaderData,
  useNavigate,
  useParams,
  useRouteError,
} from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../../generic-error";

import * as PageIndex from "./page-index";
import * as PageCountry from "./page-reviews";
import {
  OutputExchangesContinentDetail,
  RequestOutReviewType,
} from "../../../../../dtos/exchanges_out";
import ExchangesOutService from "../../../../../service/exchanges_out";
import PinMap, { MapPin, MapPinGroup } from "../../../../../components/pin-map";
import { IMAGE_HOST } from "../../../../../api/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import { path as opportunitiesPath } from "../../../index";
import { path as exchangesPath } from "../../index";
import { path as outPath } from "../index";
import { useRef } from "react";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

export const paramNames = {
  type: "type",
  continent: "continent",
};

export const path = `:${paramNames.type}/:${paramNames.continent}/`;
export const id = "country";

export type LoaderType = [OutputExchangesContinentDetail];

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderType> {
  if (!(params[paramNames.type]! in RequestOutReviewType))
    throw new TypeError("Invalid type of exchanges");

  return [
    await new ExchangesOutService().read.byContinent(
      params[paramNames.continent]!
    ),
  ];
}

export function Component() {
  const params = useParams();
  const [continent] = useLoaderData() as LoaderType;
  const type =
    RequestOutReviewType[
      params[paramNames.type] as keyof typeof RequestOutReviewType
    ];

  const navigate = useNavigate();

  const outletRef = useRef<HTMLDivElement>(null);
  const dimensions = useWindowDimensions();

  return (
    <>
      <div className="continent-map">
        <h3 className="continent-name">{continent.title}</h3>
        <PinMap
          map={
            IMAGE_HOST +
            (type === RequestOutReviewType.scope
              ? continent.image_scope
              : continent.image_score)
          }
          pins={continent.countries
            .filter((country) =>
              type === RequestOutReviewType.scope
                ? country.scope
                : country.score
            )
            .map(
              (country) =>
                new MapPinGroup(
                  country.title,
                  [
                    new MapPin(
                      type === RequestOutReviewType.scope ? "SCOPE" : "SCORE",
                      type === RequestOutReviewType.scope ? 98753 : 3033756,
                      () => {
                        navigate(country.name, { preventScrollReset: true });
                        outletRef.current?.scrollIntoView();
                        window.scrollBy(0, dimensions.height * -0.4);
                      }
                    ),
                  ],
                  country.location_x,
                  country.location_y
                )
            )}
        />
        <button
          className="close"
          onClick={() =>
            navigate(opportunitiesPath + exchangesPath + outPath, {
              preventScrollReset: true,
            })
          }
        >
          <FontAwesomeIcon icon={faClose} size="2x" />
        </button>
      </div>
      <div ref={outletRef} />
      <Outlet />
    </>
  );
}
Component.displayName = "page-oportunitati-exchanges-outgoings-continent";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName =
  "page-oportunitati-exchanges-outgoings-continent-error";

export const children = [
  <Route index {...PageIndex} />,
  <Route {...PageCountry} />,
];
