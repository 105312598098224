import React from "react";
import "./styles.css";
import logo from "../../images/logos/fasmr-simple-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/fontawesome-free-brands";
import { faChevronUp, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import OutputResource from "../../dtos/resource";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ResourceDisplay from "../resource-display";
import { path as contactPath } from "../../pages/page-contact";
import { path as sustinePath } from "../../pages/page-sustine";
import { path as noutatiPath } from "../../pages/pages-noutati";
import { path as despreNoiPath } from "../../pages/pages-despre-noi";
import { path as cdPath } from "../../pages/pages-despre-noi/page-cd";
import { path as proiectePath } from "../../pages/pages-despre-noi/pages-proiecte";
import { path as doneazaPath } from "../../pages/pages-despre-noi/pages-proiecte/page-doneaza";
import OutputLinktreeEntry from "../../dtos/linktree";

export const DEFAULT_FOOTER_LINKTREE_COUNT = 5;

// type propsType = {
//   title: OutputResource;
//   subtitle: OutputResource;
//   secondColumn: Array<OutputLinktreeEntry>;
//   facebookUrl: OutputResource;
//   instagramUrl: OutputResource;
// };

/**
 * The footer of the website pages. This should be displayed on all pages.
 *
 * @returns The react component.
 */
function PageFooter({
  title,
  subtitle,
  secondColumn,
  facebookUrl,
  instagramUrl,
}) {
  return (
    <div className="footer">
      <div className="first-row">
        <Link to={""} className="logo">
          <img src={logo} alt="logo" />
        </Link>
        <div className="title-container">
          <ResourceDisplay resource={title} className="title" wrapper="h3" />
          <ResourceDisplay
            resource={subtitle}
            className="subtitle"
            wrapper="h4"
          />
        </div>
      </div>
      <div className="content">
        <ul className="column left">
          <li>
            <Link to={sustinePath} className="element">
              Susține-ne
            </Link>
          </li>
          <li>
            <Link to={noutatiPath} className="element">
              Noutăți
            </Link>
          </li>
          <li>
            <Link to={despreNoiPath} className="element">
              Despre noi
            </Link>
          </li>
          <li>
            <Link to={despreNoiPath + cdPath} className="element">
              Consiliul director
            </Link>
          </li>
          <li>
            <Link
              to={despreNoiPath + proiectePath + doneazaPath}
              className="element"
            >
              Donează sânge, Fii erou!
            </Link>
          </li>
        </ul>
        <ul className="column right">
          {secondColumn.map((entry) => (
            <li>
              <Link to={"/" + entry.id} className="element">
                {entry.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="last-row">
        <div className="left">
          <a href={facebookUrl.value}>
            <FontAwesomeIcon icon={faFacebookF} size="2xl" className="icon" />
          </a>
          <a href={instagramUrl.value}>
            <FontAwesomeIcon icon={faInstagram} size="2xl" className="icon" />
          </a>
          <Link to={contactPath}>
            <FontAwesomeIcon icon={faEnvelope} size="2xl" className="icon" />
          </Link>
        </div>
        <div className="right">
          <button>
            <FontAwesomeIcon
              icon={faChevronUp}
              size="2xl"
              className="icon"
              onClick={() => window.scroll(0, 0)}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default PageFooter;
