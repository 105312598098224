import {
  redirect,
  useLoaderData,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { getLinktreeEntry } from "../../service/linktree/index";
import "./styles.css";
import { externalRedirect } from "../page-redirect/index.jsx";
import GenericErrorDisplay from "../generic-error";
import { AxiosError } from "axios";
import { getResources } from "../../service/resource/index";
import ResourceDisplay from "../../components/resource-display";
import ButtonPrimary from "../../components/button-primary/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export async function loader({ request }) {
  try {
    const url = new URL(request.url);
    const id = url.href.substring(url.origin.length + 1);
    const entry = await getLinktreeEntry(id);
    if (entry !== undefined) {
      if (entry.local) return redirect("/" + entry.ref);
      else return externalRedirect(entry.ref);
    }
  } catch (e) {
    if (e instanceof AxiosError && e.response && e.response.status === 404) {
      return await getResources(["not-found-image"]);
    }
    throw e;
  }
}

export function Component() {
  const [image] = useLoaderData();
  const navigate = useNavigate();

  return (
    <div id="not-found">
      <div className="text-container">
        <h1>Ooops...</h1>
        <h4>Pagina nu a fost găsită</h4>
        <ButtonPrimary onClick={() => navigate(-1)} className="button">
          <FontAwesomeIcon icon={faChevronLeft} />
          Înapoi
        </ButtonPrimary>
      </div>
      <ResourceDisplay resource={image} className="image-container" />
    </div>
  );
}
Component.displayName = "page-not-found";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-not-found-error";
