import OutputEntity, { DtoConversionError } from "..";

export default class OutputExchangesCard extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly subtitle: string | undefined,
    public readonly image: string | undefined,
    public readonly image_small: string | undefined
  ) {
    super(id);
  }

  static convert(input: any): OutputExchangesCard {
    const id = input.id ?? undefined;
    const title = input.title ?? undefined;
    const subtitle = input.subtitle ?? undefined;
    const image = input.image ?? undefined;
    const image_small = input.image_small ?? undefined;

    if (typeof id !== "number")
      throw new OutputExchangesCardConversionError("id", input);
    if (typeof title !== "string")
      throw new OutputExchangesCardConversionError("title", input);
    if (typeof subtitle !== "string" && subtitle !== undefined)
      throw new OutputExchangesCardConversionError("subtitle", input);
    if (typeof image !== "string" && image !== undefined)
      throw new OutputExchangesCardConversionError("image", input);
    if (typeof image_small !== "string" && image_small !== undefined)
      throw new OutputExchangesCardConversionError("image_small", input);

    return OutputExchangesCardConversionError.propagate(
      OutputExchangesCardConversionError.dtoName,
      () => new OutputExchangesCard(id, title, subtitle, image, image_small)
    );
  }
}

class OutputExchangesCardConversionError extends DtoConversionError {
  public static dtoName: string = "OutputExchangesCard";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputExchangesCardConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}
