import OutputEntity from "./index";

class OutputLinktreeEntry extends OutputEntity {
  constructor(
    public readonly id: string,
    public readonly ref: string,
    public readonly name: string,
    public readonly local: boolean
  ) {
    super(id);
  }
}

export default OutputLinktreeEntry;
