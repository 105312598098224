import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ButtonClipboardCopy from "../button-clipboard-copy/index";

export default function Profile({
  image,
  functie,
  nume,
  email,
  descriere,
  reverse = false,
}) {
  return (
    <div className={reverse ? "reverse componenta" : "componenta"}>
      <div className="text-content">
        <div className="functie">
          <h2>{functie}</h2>
        </div>
        <div className="descriere">{descriere}</div>
        <div className="stretcher" />
        <div className="mail-details">
          <div className="icon">
            <ButtonClipboardCopy text={email} title="E-mail">
              <FontAwesomeIcon size="4x" icon={faEnvelope} />
            </ButtonClipboardCopy>
          </div>

          <div className="email">{email}</div>
        </div>
        <div className="nume">
          <h3>{nume}</h3>
        </div>
      </div>

      <div className="imagine-profil">
        <img className="imagine" src={image} alt="" />
      </div>
    </div>
  );
}
