import ExchangesInApi from "../api/exchanges_in";
import {
  OutputExchangesCounty,
  OutputExchangesInReview,
  RequestCounties,
  RequestInReviewType,
  RequestInReviewsByCounty,
} from "../dtos/exchanges_in";
import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from ".";

class ExchangesInServiceReader extends ServiceReader {
  constructor(protected api: ExchangesInApi) {
    super();
  }

  public async counties(): Promise<OutputExchangesCounty[]> {
    const raw = await this.api.get(new RequestCounties());

    if (!Array.isArray(raw))
      throw new TypeError("Counties list is not an array.");

    return raw.map((value) => OutputExchangesCounty.convert(value));
  }

  public async byCounty(
    countyName: string,
    type: RequestInReviewType
  ): Promise<OutputExchangesInReview[]> {
    const raw = await this.api.get(
      new RequestInReviewsByCounty(countyName, type)
    );

    if (!Array.isArray(raw))
      throw new TypeError("Reviews list is not an array.");

    return raw.map((value) => OutputExchangesInReview.convert(value));
  }
}

class ExchangesInServiceCreator extends ServiceCreator {}
class ExchangesInServiceUpdater extends ServiceUpdater {}
class ExchangesInServiceDeleter extends ServiceDeleter {}

export default class ExchangesInService extends Service {
  constructor() {
    super(new ExchangesInApi());
  }

  public get read(): ExchangesInServiceReader {
    return new ExchangesInServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new ExchangesInServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new ExchangesInServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new ExchangesInServiceDeleter();
  }
}
