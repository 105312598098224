import { useEffect } from "react";
import "./styles.css";
import { AxiosError } from "axios";
import ServiceError from "../../service/error";

function GenericErrorDisplay({ error }: {error: any}) {
  useEffect(() => {
    console.log(error);
  }, [error]);
  return (
    <div className="generic-error">
      {error instanceof AxiosError ? (
        <>
          {error.response ? (
            <>
              <h3>{error.response.status + " " + error.response.statusText}</h3>
              <p>{error.response.data}</p>
            </>
          ) : error.request ? (
            <>
              <h3>Server down</h3>
              <p>{error.request.data}</p>
            </>
          ) : (
            <>
              <h3>Error</h3>
            </>
          )}
          <i>Axios code: {error.code}</i>
          <p>Message: {error.message}</p>
        </>
      ) : error instanceof ServiceError ? (
        <>
          <h3>Service error: {error.title}</h3>
          <p>{error.body}</p>
        </>
      ) : (
        <>
          <h3>Error</h3>
          <p>{error.toString()}</p>
        </>
      )}
    </div>
  );
}

export default GenericErrorDisplay;
