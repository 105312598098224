import { SyncLoader } from "react-spinners";
import { OutputResourceType } from "../../dtos/resource";
import STYLES_CONSTANTS from "../../styles/index";
import "./styles.css";
import { IMAGE_HOST } from "../../api/config";
const Markdown = require("react-remarkable");

function ResourceDisplay({
  resource,
  className = "",
  wrapper = "",
  ...props
}: any) {
  const Wrapper =
    wrapper === undefined || wrapper === null || wrapper.length === 0
      ? "div"
      : wrapper;

  return (
    <Wrapper
      className={
        "resource-container " +
        (resource !== undefined && resource != null
          ? resource.type === OutputResourceType.image
            ? "image "
            : "text "
          : "") +
        (className && className)
      }
      {...props}
    >
      {resource !== undefined && resource != null ? (
        resource.type === OutputResourceType.text ? (
          <>{resource.value}</>
        ) : resource.type === OutputResourceType.md ? (
          <Markdown source={resource.value} />
        ) : resource.type === OutputResourceType.image ? (
          <img src={IMAGE_HOST + resource.value} alt={resource.id} />
        ) : (
          <></>
        )
      ) : (
        <SyncLoader color={STYLES_CONSTANTS["--color-fasmr-blue"]} />
      )}
    </Wrapper>
  );
}

export default ResourceDisplay;
