import { get, getList } from "../../api/linktree/get";
import ServiceError from "../error";
import OutputLinktreeEntry from "../../dtos/linktree";
import { AxiosError } from "axios";

export function toOutputLinktreeEntry(data: any): OutputLinktreeEntry {
  if (data.id === undefined || data.id === null)
    throw new ServiceError("Invalid linktree entry", "Empty id.", data);

  if (data.ref === undefined || data.ref === null)
    throw new ServiceError(
      "Invalid linktree entry",
      `Empty ref for entry with id ${data.id}.`,
      data
    );

  if (data.local === undefined || data.local === null) data.local = true;
  if (data.name === undefined || data.name === null) data.local = "";

  return new OutputLinktreeEntry(
    String(data.id),
    String(data.ref),
    String(data.name),
    !!data.local
  );
}

export async function getLinktreeEntry(
  id: string
): Promise<OutputLinktreeEntry | undefined> {
  try {
    return toOutputLinktreeEntry(await get(id.trim()));
  } catch (error) {
    if (!(error instanceof AxiosError)) throw error;
    if (error.code === "404") return;
    throw error;
  }
}

export async function getLinktreeList(
  count: number | undefined
): Promise<Array<OutputLinktreeEntry>> {
  const rawData = await getList(count);
  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid linktree list array",
      "Contact administrator.",
      rawData
    );

  const arrayData: any[] = rawData;

  return arrayData.map((entry) => toOutputLinktreeEntry(entry));
}
