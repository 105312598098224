import {
  RequestContinentByName,
  RequestContinents,
  RequestOutReviewsByCountry,
} from "../dtos/exchanges_out";
import Api from "./index";

export default class ExchangesApi extends Api {
  constructor() {
    super("/exchanges_out.php");
  }

  public get(
    params:
      | RequestContinents
      | RequestContinentByName
      | RequestOutReviewsByCountry
  ): Promise<any> {
    return super.get(params);
  }
}
