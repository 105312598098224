import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import {
  faThumbsDown,
  faThumbsUp,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export class ReviewDisplayReview {
  constructor(
    public readonly name: string,
    public readonly subName: string,
    public readonly title: string,
    public readonly pros: string,
    public readonly cons?: string,
    public readonly side?: string
  ) {}
}

function ReviewDisplay({ review }: { review: ReviewDisplayReview }) {
  return (
    <div className="exchanges-out-review">
      <div className="left">
        <div className="profile">
          <FontAwesomeIcon
            icon={faUser}
            size="2xl"
            color="var(--color-fasmr-blue)"
          />
          <b>{review.name}</b>
          <span>{review.subName}</span>
        </div>

        {review.side ? <div className="city">{review.side}</div> : <div />}
      </div>
      <div className="right">
        <h3>{review.title}</h3>
        <div className="part-container">
          {review.cons !== undefined && (
            <FontAwesomeIcon icon={faThumbsUp} size="2xl" />
          )}
          <div className="text-container">{review.pros}</div>
        </div>
        {review.cons !== undefined && (
          <div className="part-container">
            <FontAwesomeIcon icon={faThumbsDown} size="2xl" />
            <div className="text-container">{review.cons}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewDisplay;
