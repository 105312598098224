import OutputResource, { OutputResourceType } from "../../dtos/resource";
import { get } from "../../api/resource/get";
import ServiceError from "../error";

export function toOutputResource(resource: any): OutputResource {
  if (resource.id === undefined || resource.id === null)
    throw new ServiceError("Invalid resource", "Empty id.", resource);

  if (resource.type === undefined || resource.type === null)
    throw new ServiceError(
      "Invalid resource",
      `Empty type for resource ${resource.id}.`,
      resource
    );
  if (resource.value === undefined || resource.value === null)
    throw new ServiceError(
      "Invalid resource",
      `Empty value for resource ${resource.id}.`,
      resource
    );

  const type =
    OutputResourceType[
      String(resource.type) as keyof typeof OutputResourceType
    ];
  if (type === undefined || type === null)
    throw new ServiceError(
      "Invalid resource",
      `Type ${resource.type} not accepted for resource ${resource.id}.`
    );

  return new OutputResource(String(resource.id), type, String(resource.value));
}

export async function getResources(
  ids: string[] | string
): Promise<OutputResource[] | OutputResource> {
  const rawData: any = await get(ids);
  if (!Array.isArray(rawData)) return toOutputResource(rawData);

  const arrayData: any[] = rawData;

  return arrayData.map((resource) => toOutputResource(resource));
}
