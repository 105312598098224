import OutputEntity from "./index";

class OutputPerson extends OutputEntity {
  public readonly profileLocation: string;

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly position: string,
    public readonly email: string,
    profileUri: string,
    imageHost: string,
    public readonly description?: string,
    public readonly position_short?: string
  ) {
    super(id);
    this.profileLocation = imageHost + profileUri;
  }
}

export default OutputPerson;
