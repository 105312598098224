import OutputEntity, { DtoConversionError, RequestEntity } from "./index";
import OutputResource, { OutputResourceType } from "./resource";

export default class OutputGalleryEntry extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly location: OutputResource,
    public readonly structure?: string,
    public readonly caption?: string
  ) {
    super(id);
  }

  static convert(input: any): OutputGalleryEntry {
    const id = input.id ?? undefined;
    const location = input.location ?? undefined;
    const structure = input.structure ?? undefined;
    const caption = input.caption ?? undefined;

    if (typeof id !== "number" || !Number.isInteger(id))
      throw new OutputGalleryEntryConversionError("id", input);
    if (typeof location !== "string")
      throw new OutputGalleryEntryConversionError("location", input);
    if (typeof structure !== "string" && structure !== undefined)
      throw new OutputGalleryEntryConversionError("structure", input);
    if (typeof caption !== "string" && caption !== undefined)
      throw new OutputGalleryEntryConversionError("caption", input);

    return OutputGalleryEntryConversionError.propagate(
      OutputGalleryEntryConversionError.dtoName,
      () =>
        new OutputGalleryEntry(
          id,
          new OutputResource(
            "gallery-entry " + id,
            OutputResourceType.image,
            location
          ),
          structure,
          caption
        )
    );
  }
}

class OutputGalleryEntryConversionError extends DtoConversionError {
  public static dtoName: string = "OutputGalleryEntry";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputGalleryEntryConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class RequestGalleryEntriesByStructure extends RequestEntity {
  constructor(public readonly structure: string) {
    super();
  }
}
