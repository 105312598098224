import React from "react";
import { Outlet, Route } from "react-router-dom";
import "./styles.css";

import * as OportunitatiIndex from "./page-oportunitati-index";
import * as Exchanges from "./page-exchanges";
import * as Transmed from "./page-transmed";

export const path = "/opportunities/";

export function Component() {
  return <Outlet />;
}
Component.displayName = "page-oportunitati";

export const children = [
  <Route index {...OportunitatiIndex} />,
  <Route {...Exchanges} />,
  <Route {...Transmed} />,
];
