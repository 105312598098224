import ResourceApi from "../api/resource";
import OutputResource, { RequestResourcesByIds } from "../dtos/resource";
import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from ".";

class ResourceServiceReader extends ServiceReader {
  constructor(protected api: ResourceApi) {
    super();
  }

  public async byIds(
    ids: string[]
  ): Promise<{ [key: string]: OutputResource }> {
    const raw = await this.api.get(new RequestResourcesByIds(ids));

    if (!Array.isArray(raw))
      throw new TypeError("Resources list is not an array.");

    return raw
      .map((value) => OutputResource.convert(value))
      .reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: OutputResource });
  }

  public async byId(id: string): Promise<OutputResource> {
    return OutputResource.convert(
      await this.api.get(new RequestResourcesByIds(id))
    );
  }
}

class ResourceServiceCreator extends ServiceCreator {}
class ResourceServiceUpdater extends ServiceUpdater {}
class ResourceServiceDeleter extends ServiceDeleter {}

export default class ResourceService extends Service {
  constructor() {
    super(new ResourceApi());
  }

  public get read(): ResourceServiceReader {
    return new ResourceServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new ResourceServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new ResourceServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new ResourceServiceDeleter();
  }
}
