import OutputEntity, {
  DtoConversionError,
  enumConvert,
  RequestEntity,
} from "./index";

export enum OutputResourceType {
  "text" = "text",
  "image" = "image",
  "md" = "md",
}

export default class OutputResource extends OutputEntity {
  constructor(
    public readonly id: string,
    public readonly type: OutputResourceType,
    public readonly value: string
  ) {
    super(id);
  }

  static convert(input: any): OutputResource {
    const id = input.id ?? undefined;
    const type = input.type ?? undefined;
    const value = input.value ?? undefined;

    if (typeof id !== "string")
      throw new OutputResourceConversionError("id", input);
    if (typeof type !== "string")
      throw new OutputResourceConversionError("type", input);
    if (typeof value !== "string")
      throw new OutputResourceConversionError("value", input);

    return OutputResourceConversionError.propagate(
      OutputResourceConversionError.dtoName,
      () => new OutputResource(id, enumConvert(OutputResourceType, type), value)
    );
  }
}

class OutputResourceConversionError extends DtoConversionError {
  public static dtoName: string = "OutputResource";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(OutputResourceConversionError.dtoName, propertyName, rawData, hint);
  }
}

export class RequestResourcesByIds extends RequestEntity {
  constructor(public readonly resources: string | string[]) {
    super();
  }
}
