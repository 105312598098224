import React, { useRef } from "react";
import {
  Outlet,
  Route,
  useLoaderData,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../generic-error";
import OutputResource from "../../../../dtos/resource";
import {
  OutputExchangesCounty,
  RequestInReviewType,
} from "../../../../dtos/exchanges_in";
import ResourceService from "../../../../service/resource";
import ExchangesInService from "../../../../service/exchanges_in";
import { IMAGE_HOST } from "../../../../api/config";
import PinMap, { MapPin, MapPinGroup } from "../../../../components/pin-map";

import * as PageIndex from "./page-index";
import * as PageReviews from "./page-reviews";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

export const path = "in";
export const id = "in";

const resourceKeys = {
  headerWide: "opportunities-exchanges-romania-map",
};

export type LoaderType = [
  { [key: string]: OutputResource },
  OutputExchangesCounty[]
];

export async function loader(): Promise<LoaderType> {
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  const counties = new ExchangesInService().read.counties();
  return [await resources, await counties];
}

export function Component() {
  const [resources, counties] = useLoaderData() as LoaderType;
  const navigate = useNavigate();

  const outletRef = useRef<HTMLDivElement>(null);
  const dimensions = useWindowDimensions();

  return (
    <div id="incomings">
      <PinMap
        map={IMAGE_HOST + resources[resourceKeys.headerWide].value}
        pins={counties.map((county) => {
          const pins: MapPin[] = [];

          if (county.scope)
            pins.push(
              new MapPin(
                "SCOPE",
                98753,
                () =>
                  navigate(`${RequestInReviewType.scope}/${county.name}/`, {
                    preventScrollReset: true,
                  }),
                undefined
              )
            );
          if (county.score)
            pins.push(
              new MapPin(
                "SCORE",
                3033756,
                () => {
                  navigate(`${RequestInReviewType.score}/${county.name}/`, {
                    preventScrollReset: true,
                  });

                  outletRef.current?.scrollIntoView();
                  window.scrollBy(0, dimensions.height * -0.4);
                },
                undefined
              )
            );
          if (county.trip)
            pins.push(
              new MapPin(
                "National Trip",
                16711680,
                () =>
                  navigate(`${RequestInReviewType.trip}/${county.name}/`, {
                    preventScrollReset: true,
                  }),
                undefined
              )
            );

          return new MapPinGroup(
            county.title,
            pins,
            county.location_x,
            county.location_y
          );
        })}
      />
      <div ref={outletRef} />
      <Outlet />
    </div>
  );
}
Component.displayName = "page-oportunitati-exchanges-incomings";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-exchanges-incomings-error";

export const children = [
  <Route index {...PageIndex} />,
  <Route {...PageReviews} />,
];
