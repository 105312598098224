import OutputEntity, { DtoConversionError, enumConvert } from "..";

export enum OutputTransmedCardImagePosition {
  "top" = "top",
  "bottom" = "bottom",
  "left" = "left",
  "right" = "right",
}

export default class OutputTransmedCard extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly subtitle: string | undefined,
    public readonly image: string | undefined,
    public readonly image_position: OutputTransmedCardImagePosition,
    public readonly background: string | undefined,
    public readonly background_position: OutputTransmedCardImagePosition,
    public readonly button_title: string | undefined,
    public readonly button_url: string | undefined
  ) {
    super(id);
  }

  static convert(input: any): OutputTransmedCard {
    const id = input.id ?? undefined;
    const title = input.title ?? undefined;
    const subtitle = input.subtitle ?? undefined;
    const image = input.image ?? undefined;
    const image_position = input.image_position ?? undefined;
    const background = input.background ?? undefined;
    const background_position = input.background_position ?? undefined;
    const button_title = input.button_title ?? undefined;
    const button_url = input.button_url ?? undefined;

    if (typeof id !== "number")
      throw new OutputTransmedCardConversionError("id", input);
    if (typeof title !== "string")
      throw new OutputTransmedCardConversionError("title", input);
    if (typeof subtitle !== "string" && subtitle !== undefined)
      throw new OutputTransmedCardConversionError("subtitle", input);
    if (typeof image !== "string" && image !== undefined)
      throw new OutputTransmedCardConversionError("image", input);
    if (typeof image_position !== "string")
      throw new OutputTransmedCardConversionError("image_position", input);
    if (typeof background !== "string" && background !== undefined)
      throw new OutputTransmedCardConversionError("background", input);
    if (typeof background_position !== "string")
      throw new OutputTransmedCardConversionError("background_position", input);
    if (typeof button_title !== "string" && button_title !== undefined)
      throw new OutputTransmedCardConversionError("button_title", input);
    if (typeof button_url !== "string" && button_title !== undefined)
      throw new OutputTransmedCardConversionError("button_url", input);

    return OutputTransmedCardConversionError.propagate(
      OutputTransmedCardConversionError.dtoName,
      () =>
        new OutputTransmedCard(
          id,
          title,
          subtitle,
          image,
          enumConvert(OutputTransmedCardImagePosition, image_position),
          background,
          enumConvert(OutputTransmedCardImagePosition, background_position),
          button_title,
          button_url
        )
    );
  }
}

class OutputTransmedCardConversionError extends DtoConversionError {
  public static dtoName: string = "OutputTransmedCard";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputTransmedCardConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}
