import { useEffect, useState } from "react";
import ResourceService from "../../service/resource";
import OutputResource from "../../dtos/resource";
import ResourceDisplay from "../resource-display";

import { path as oportunitatiPath } from "../../pages/pages-oportunitati";
import { path as exchangesPath } from "../../pages/pages-oportunitati/page-exchanges";
import { path as inPath } from "../../pages/pages-oportunitati/page-exchanges/incomings";
import { path as outPath } from "../../pages/pages-oportunitati/page-exchanges/outgoings";

import "./styles.css";
import { NavLink } from "react-router-dom";

const resourceKeys = {
  up: "opportunities-exchanges-plane-up",
  down: "opportunities-exchanges-plane-down",
};

export enum ExchangesType {
  "in" = "in",
  "out" = "out",
}

function ButtonExchanges({ type }: { type: ExchangesType }) {
  const [resources, setResources] = useState<{
    [key: string]: OutputResource;
  }>({});
  useEffect(() => {
    new ResourceService().read
      .byIds(Object.values(resourceKeys))
      .then((r) => setResources(r));
  }, []);

  return (
    <NavLink
      to={
        oportunitatiPath +
        exchangesPath +
        (type === ExchangesType.in ? inPath : outPath)
      }
      className={({ isActive, isPending }) =>
        "exchanges-button" + (isActive || isPending ? " active" : "")
      }
    >
      <div className="element">
        <div className="inside">
          <ResourceDisplay
            resource={
              resources[
                type === ExchangesType.out ? resourceKeys.up : resourceKeys.down
              ]
            }
            className="picture"
          />
        </div>
      </div>
      <div className="element">
        <div className="inside">
          <div className="elements">
            <div />
            <div />
          </div>
          <h2>{type === ExchangesType.out ? "OUTGOINGS" : "INCOMINGS"}</h2>
        </div>
      </div>
    </NavLink>
  );
}

export default ButtonExchanges;
