import ExchangesCardApi from "../../api/exchanges_card";
import OutputExchangesCard from "../../dtos/exchanges/card";
import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from "..";

class ExchangesCardServiceReader extends ServiceReader {
  constructor(protected api: ExchangesCardApi) {
    super();
  }

  public async all(): Promise<OutputExchangesCard[]> {
    const raw = await this.api.get();
    if (!Array.isArray(raw))
      throw new TypeError("ExchangesCard entry list is not an array.");

    return raw.map((value) => OutputExchangesCard.convert(value));
  }
}

class ExchangesCardServiceCreator extends ServiceCreator {}
class ExchangesCardServiceUpdater extends ServiceUpdater {}
class ExchangesCardServiceDeleter extends ServiceDeleter {}

export default class ExchangesCardService extends Service {
  constructor() {
    super(new ExchangesCardApi());
  }

  public get read(): ExchangesCardServiceReader {
    return new ExchangesCardServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new ExchangesCardServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new ExchangesCardServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new ExchangesCardServiceDeleter();
  }
}
