import React, { MouseEventHandler, ReactNode, useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
const bulletpointNeuron = require("../../../../images/bulletpoint-neuron.svg");

type NodePropsType = {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  [others: string]: any;
};

function TreeNavigationMenuNode(props: NodePropsType) {
  const [active, setActive] = useState(false);
  return (
    <div
      className={
        "tree-navigation-menu-node " +
        (active ? "active " : "") +
        props.className
      }
      {...props.others}
    >
      <div onClick={() => setActive(!active)} className="title">
        <img src={bulletpointNeuron} alt="" />
        <div className="text">{props.title}</div>
        <FontAwesomeIcon
          icon={active ? faChevronUp : faChevronDown}
          color={active ? "var(--color-fasmr-blue)" : "var(--color-dark)"}
        />
      </div>
      <div className={"content"}>{props.children}</div>
    </div>
  );
}

export default TreeNavigationMenuNode;

type LinkNodePropsType = {
  children?: ReactNode;
  href: string;
  className?: string;
  [others: string]: any;
};

export function TreeNavigationMenuLinkNode(props: LinkNodePropsType) {
  return (
    <div
      className={"tree-navigation-menu-node w-padding " + props.className}
      {...props.others}
    >
      <Link
        to={props.href}
        className="title"
        style={{
          color: "inherit",
          textDecoration: "none",
          padding: 0,
        }}
      >
        <img src={bulletpointNeuron} alt="" />
        <div className="text">{props.children}</div>
        <FontAwesomeIcon icon={faChevronRight} color={"var(--color-dark)"} />
      </Link>
    </div>
  );
}

type ButtonNodePropsType = {
  children?: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  [others: string]: any;
};

export function TreeNavigationMenuButtonNode(props: ButtonNodePropsType) {
  return (
    <div
      className={"tree-navigation-menu-node w-padding" + props.className}
      {...props.others}
    >
      <button onClick={props.onClick} className="title">
        <img src={bulletpointNeuron} alt="" />
        <div className="text">{props.children}</div>
      </button>
    </div>
  );
}
