import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from "..";
import TransmedGalleryApi from "../../api/transmed_gallery";
import {
  OutputTransmedGalleryEntry,
  RequestGalleryEntriesByCounty,
} from "../../dtos/transmed/gallery";

class TransmedGalleryServiceReader extends ServiceReader {
  constructor(protected api: TransmedGalleryApi) {
    super();
  }

  public async byCounty(
    countyName: string
  ): Promise<OutputTransmedGalleryEntry[]> {
    const raw = await this.api.get(
      new RequestGalleryEntriesByCounty(countyName)
    );
    if (!Array.isArray(raw))
      throw new TypeError("TransmedGallery is not an array.");

    return raw.map((value) => OutputTransmedGalleryEntry.convert(value));
  }
}

class TransmedGalleryServiceCreator extends ServiceCreator {}
class TransmedGalleryServiceUpdater extends ServiceUpdater {}
class TransmedGalleryServiceDeleter extends ServiceDeleter {}

export default class TransmedGalleryService extends Service {
  constructor() {
    super(new TransmedGalleryApi());
  }

  public get read(): TransmedGalleryServiceReader {
    return new TransmedGalleryServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new TransmedGalleryServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new TransmedGalleryServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new TransmedGalleryServiceDeleter();
  }
}
