import { getCount, getList, getName } from "../../api/news/get";
import { OutputNewsSummary } from "../../dtos/news";
import ServiceError from "../error";
import { toOutputNewsSummary } from "./index";
import OutputNewsDetail from "../../dtos/news";
import { toOutputNewsDetail } from "./index";

export async function getNewsCount(search?: string): Promise<number> {
  const trimmedSearch = search?.trim();
  const rawObj =
    trimmedSearch !== undefined && trimmedSearch.length > 0
      ? await getCount(trimmedSearch)
      : await getCount();

  if (
    typeof rawObj !== "object" ||
    rawObj.count === undefined ||
    rawObj.count == null
  )
    throw new ServiceError(
      "Invalid news count",
      "Server sent invalid response. Please contact administrator.",
      rawObj
    );
  const res = parseInt(rawObj.count);
  if (isNaN(res))
    throw new ServiceError(
      "Invalid news count",
      `Value ${res} is not a number.`,
      rawObj
    );
  return res;
}

export async function getNewsList(
  page?: number,
  size?: number,
  search?: string
): Promise<OutputNewsSummary[]> {
  const trimmedSearch = search?.trim();
  const rawData =
    trimmedSearch !== undefined && trimmedSearch.length > 0
      ? await getList(page, size, trimmedSearch)
      : await getList(page, size);

  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid news list array",
      "Invalid news list array.",
      rawData
    );

  const arrayData: any[] = rawData;

  return arrayData.map((news) => toOutputNewsSummary(news));
}

export async function getNewsDetail(name: string): Promise<OutputNewsDetail> {
  const rawData = await getName(name);

  return toOutputNewsDetail(rawData);
}
