import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/fontawesome-free-brands";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ResourceDisplay from "../../../../components/resource-display";
import { getResources } from "../../../../service/resource/index";
import { Link, useLoaderData, useRouteError } from "react-router-dom";
import { useState, useEffect } from "react";
import GenericErrorDisplay from "../../../generic-error";
import Perfuzie from "../../../../components/perfuzie-doneaza/index.jsx";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import GalleryService from "../../../../service/gallery";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { externalRedirectUrl } from "../../../page-redirect/index.jsx";
import ButtonClipboardCopy from "../../../../components/button-clipboard-copy/index";

const structureId = "doneaza";
export const path = structureId + "/";

const resourceKeys = {
  pageTitle: "about-projects-doneaza-title",
  pageSubtitle: "about-projects-doneaza-subtitle",
  headerDoneaza: "about-doneaza-header",
  headerDoneaza2: "about-doneaza-header-2",
  bodyText: "about-doneaza-bodyText",
  logo: "about-doneaza-logo",
  text1: "about-doneaza-text-primapunga",
  text2: "about-doneaza-text-adouapunga",
  text3: "about-doneaza-text-atreiapunga",
  blood: "about-doneaza-sange",
};

export async function loader() {
  const resourcesArray = getResources(Object.values(resourceKeys));
  const gallery = new GalleryService().read.byStructure(structureId);
  return [await resourcesArray, await gallery];
}

export function Component() {
  const [resourcesArray, gallery] = useLoaderData();
  const [headerDoneaza, setHeaderDoneaza] = useState(null);
  const [headerDoneaza2, setHeaderDoneaza2] = useState(null);
  const [bodyText, setBodyText] = useState(null);
  const [logo, setLogo] = useState(null);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [text1, setText1] = useState(null);
  const [text2, setText2] = useState(null);
  const [text3, setText3] = useState(null);
  const [blood, setBlood] = useState(null);
  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setHeaderDoneaza(null);
      setHeaderDoneaza2(null);
      setBodyText(null);
      setLogo(null);
      setText1(null);
      setText2(null);
      setText3(null);
      setBlood(null);
      setTitle(null);
      setSubtitle(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.headerDoneaza)
        setHeaderDoneaza(resource);
      if (resource.id === resourceKeys.headerDoneaza2)
        setHeaderDoneaza2(resource);
      if (resource.id === resourceKeys.bodyText) setBodyText(resource);
      if (resource.id === resourceKeys.logo) setLogo(resource);
      if (resource.id === resourceKeys.text1) setText1(resource);
      if (resource.id === resourceKeys.text2) setText2(resource);
      if (resource.id === resourceKeys.text3) setText3(resource);
      if (resource.id === resourceKeys.blood) setBlood(resource);
      if (resource.id === resourceKeys.pageTitle) setTitle(resource);
      if (resource.id === resourceKeys.pageSubtitle) setSubtitle(resource);
    });
  }, [resourcesArray]);

  const dimensions = useWindowDimensions();

  return (
    <div className="page-doneaza">
      <div className="header">
        {dimensions.width > 600 ? (
          <>
            <ResourceDisplay
              resource={title}
              wrapper={dimensions.width > 1350 ? "h1" : "h3"}
              className="header-text"
            />
            <div className="header-image">
              <ResourceDisplay
                resource={headerDoneaza}
                className="header-doneaza"
              />
            </div>
          </>
        ) : (
          <>
            <div className="header-image">
              <ResourceDisplay
                resource={headerDoneaza2}
                className="header-doneaza"
              />
            </div>
          </>
        )}
        <div className="header-subtitle">
          <ResourceDisplay resource={subtitle} wrapper="h2" />
        </div>
      </div>
      <div className="body">
        {/* <h2 className="body-title">Despre proiect</h2> */}
        <div className="body-paragh content-container">
          <ResourceDisplay resource={bodyText} />
        </div>
        <div className="perfuzii">
          <Perfuzie text={text1} sange={blood} number={1} />
          <Perfuzie text={text2} sange={blood} number={2} />
          <Perfuzie text={text3} sange={blood} number={3} />
        </div>
        <div className="container-fasmr">
          <div className="logo">
            <ResourceDisplay resource={logo} />
          </div>
          <div className="icons">
            <Link
              to={externalRedirectUrl(
                "https://www.facebook.com/doneazasangefiierou"
              )}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link
              to={externalRedirectUrl(
                "https://www.instagram.com/doneazasangefiierou.national"
              )}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>

            <ButtonClipboardCopy text={"doneazasange@fasmr.ro"} title="E-mail">
              <FontAwesomeIcon icon={faEnvelope} size="2xl" />
            </ButtonClipboardCopy>
          </div>
        </div>
      </div>
      <div className="images">
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 300: 1, 600: 2, 900: 3, 1200: 4, 1600: 5 }}
        >
          <Masonry gutter="var(--gap-small)">
            {gallery.map((element, index) => (
              <ResourceDisplay resource={element.location} key={index} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
}
Component.displayName = "page-about-projects-doneaza";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-about-projects-doneaza-error";
