import "./styles.css";
import "swiper/css";
import "swiper/css/effect-cards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResourceDisplay from "../../../../components/resource-display";
import CardPerson from "../../../../components/card-person/index";
import CardStructure from "../../../../components/card-structure/index";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";

import { path as cdPath } from "../../page-cd/index.jsx";
import { path as aboutPath } from "../../index.jsx";
import { path as projectsPath } from "../../pages-proiecte/index.jsx";
import { path as divisionsPath } from "../../page-divizii/index.jsx";
import { path as comiteesPath } from "../../pages-comitete/index.jsx";
import { Link } from "react-router-dom";

export const id = "structura";
function SectionStructura({ title, cd, committees, divisions, projects }) {
  const [cdTitle, cdText, cdPeople] = cd;
  const [committeesTitle, committeesText, committeesList] = committees;
  const [divisionsTitle, divisionsText, divisionsList] = divisions;
  const [projectsTitle, projectsText, projectsList] = projects;
  return (
    <div id={id} className="container-wrapper">
      <div className="container content-container">
        <ResourceDisplay resource={title} wrapper="h2" />
        <div id="cd" className="subsection">
          <div className="information">
            <div className="text-container">
              <ResourceDisplay
                resource={cdTitle}
                wrapper="h3"
                className="title"
              />
              <ResourceDisplay resource={cdText} className="text" />
            </div>
            <Link to={cdPath} className="fill-secondary-button w-gap">
              <span>Află mai multe</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </div>
          <div className="cards-container">
            <div className="cards-grid">
              {cdPeople?.map((person) => (
                <CardPerson person={person} />
              ))}
            </div>
            <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
              {cdPeople?.map((person) => (
                <SwiperSlide>
                  <CardPerson person={person} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div id="comitete" className="subsection">
          <div className="information">
            <div className="text-container">
              <ResourceDisplay
                resource={committeesTitle}
                wrapper="h3"
                className="title"
              />
              <ResourceDisplay resource={committeesText} className="text" />
            </div>
          </div>
          <div className="cards-container">
            <div className="cards-grid">
              {committeesList?.map((committee) => (
                <CardStructure
                  treeRoot={aboutPath + comiteesPath}
                  structure={committee}
                />
              ))}
            </div>
            <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
              {committeesList?.map((committee) => (
                <SwiperSlide>
                  <CardStructure
                    treeRoot={aboutPath + comiteesPath}
                    structure={committee}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div id="divizii" className="subsection">
          <div className="information">
            <div className="text-container">
              <ResourceDisplay
                resource={divisionsTitle}
                wrapper="h3"
                className="title"
              />
              <ResourceDisplay resource={divisionsText} className="text" />
            </div>
            <Link
              to={aboutPath + divisionsPath}
              className="fill-secondary-button w-gap"
            >
              <span>Află mai multe</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </div>
          <div className="cards-container">
            <div className="cards-grid">
              {divisionsList?.map((division) => (
                <CardStructure
                  treeRoot={aboutPath + divisionsPath + "#"}
                  structure={division}
                />
              ))}
            </div>
            <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
              {divisionsList?.map((division) => (
                <SwiperSlide>
                  <CardStructure
                    treeRoot={aboutPath + divisionsPath + "#"}
                    structure={division}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div id="proiecte" className="subsection">
          <div className="information">
            <div className="text-container">
              <ResourceDisplay
                resource={projectsTitle}
                wrapper="h3"
                className="title"
              />
              <ResourceDisplay resource={projectsText} className="text" />
            </div>
          </div>
          <div className="cards-container">
            <div className="cards-grid">
              {projectsList?.map((project) => (
                <CardStructure
                  treeRoot={aboutPath + projectsPath}
                  structure={project}
                  inverse
                />
              ))}
            </div>
            <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
              {projectsList?.map((project) => (
                <SwiperSlide>
                  <CardStructure
                    treeRoot={aboutPath + projectsPath}
                    structure={project}
                    inverse
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionStructura;
