import { useLoaderData, useNavigate, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../../generic-error";
import ResourceDisplay from "../../../../../components/resource-display";
import OutputResource from "../../../../../dtos/resource";
import ResourceService from "../../../../../service/resource";
import ExchangesOutService from "../../../../../service/exchanges_out";
import {
  OutputExchangesContinentList,
  RequestOutReviewType,
} from "../../../../../dtos/exchanges_out";
import PinMap, { MapPin, MapPinGroup } from "../../../../../components/pin-map";
import { IMAGE_HOST } from "../../../../../api/config";

const resourceKeys = {
  headerWide: "opportunities-exchanges-world-map",
};

type LoaderType = [
  { [key: string]: OutputResource },
  OutputExchangesContinentList[]
];

export async function loader(): Promise<LoaderType> {
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  const continents = new ExchangesOutService().read.continents();
  return [await resources, await continents];
}

export function Component() {
  const [resources, continents] = useLoaderData() as LoaderType;
  const navigate = useNavigate();

  return (
    <PinMap
      map={IMAGE_HOST + resources[resourceKeys.headerWide].value}
      around
      multipleGap={40}
      rotationSpeed={0.8}
      multipleGapPadding={15}
      pins={continents.map((continent: OutputExchangesContinentList) => {
        const pins: MapPin[] = [];

        if (continent.scopes > 0)
          pins.push(
            new MapPin(
              "SCOPE",
              98753,
              () =>
                navigate(`${RequestOutReviewType.scope}/${continent.name}/`, {
                  preventScrollReset: true,
                }),
              undefined,
              <div className="continent scope">{continent.scopes}</div>
            )
          );
        if (continent.scores > 0)
          pins.push(
            new MapPin(
              "SCORE",
              3033756,
              () =>
                navigate(`${RequestOutReviewType.score}/${continent.name}/`, {
                  preventScrollReset: true,
                }),
              undefined,
              <div className="continent score">{continent.scores}</div>
            )
          );

        return new MapPinGroup(
          continent.title,
          pins,
          continent.location_x,
          continent.location_y
        );
      })}
    />
  );
}
Component.displayName = "page-oportunitati-exchanges-outgoings-index";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-exchanges-outgoings-index-error";
