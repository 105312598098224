import { ReactElement, useEffect, useState } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { colorHexFromNumber } from "../../utils/color";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export class MapPin {
  public constructor(
    public readonly title: string,
    public readonly color: number,
    public readonly onSelect?: () => void,
    public readonly key?: any,
    public readonly pin?: ReactElement,
    public readonly stickX?: number,
    public readonly stickY?: number
  ) {}
}

export class MapPinGroup {
  public constructor(
    public readonly title: string,
    public readonly pins: MapPin[],
    public readonly locationX: number,
    public readonly locationY: number,
    public readonly icon?: ReactElement,
    public readonly pin?: ReactElement,
    public readonly around?: boolean,
    public readonly multipleGap?: number,
    public readonly multipleGapPadding?: number,
    public readonly stickX?: number,
    public readonly stickY?: number
  ) {}
}

type PropsType = {
  map: string;
  pins: MapPinGroup[];
  onSelect?: (selected: MapPin) => void;
  pin?: ReactElement;
  around?: boolean;
  multipleGap?: number;
  multipleGapPadding?: number;
  stickX?: number;
  stickY?: number;
  rotationSpeed?: number;
};

const initialRoundThetaOffset = (Math.PI * 7) / 4;

function PinMap({
  map,
  pins,
  onSelect,
  pin = <FontAwesomeIcon icon={faLocationDot} size="2xl" />,
  around = false,
  multipleGap = around ? 30 : 5,
  multipleGapPadding = 0,
  stickX = 50,
  stickY = 100,
  rotationSpeed = 1,
}: PropsType) {
  const [expanded, setExpanded] = useState<number | null>(null);
  const [roundThetaOffset, setRoundThetaOffset] = useState<number>(
    initialRoundThetaOffset
  );
  const [rotate, setRotate] = useState<boolean>(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (rotate) {
      const interval = setInterval(() => {
        let newVal = roundThetaOffset + (rotationSpeed * Math.PI) / 360;
        while (newVal > 2 * Math.PI) newVal -= 2 * Math.PI;
        setRoundThetaOffset(newVal);
      }, 10);

      return () => clearInterval(interval);
    }
  }, [rotationSpeed, roundThetaOffset, rotate]);

  return width > 650 ? (
    <div className="pin-map" onClick={() => setExpanded(null)}>
      <>
        <img src={map} alt={map} className="map" />
        {pins.map((pinGroup: MapPinGroup, groupIndex) => {
          const roundGap = (2 * Math.PI) / pinGroup.pins.length;

          return (
            pinGroup.pins.length > 0 && (
              <div
                key={groupIndex}
                className={
                  "pin-group" + (expanded === groupIndex ? " expanded" : "")
                }
                style={{
                  ...{
                    top: `${pinGroup.locationY / 10}%`,
                    left: `${pinGroup.locationX / 10}%`,
                    transform:
                      pinGroup.pins.length === 1 || !(pinGroup.around ?? around)
                        ? `translate(-${pinGroup.stickX ?? stickX}%, -${
                            pinGroup.stickY ?? stickY
                          }%)`
                        : "translate(-50%, -50%)",
                  },
                  ...{
                    "--multiple-gap-padding":
                      pinGroup.multipleGapPadding ?? multipleGapPadding,
                  },
                }}
                onClick={
                  pinGroup.pins.length === 1
                    ? () => {
                        if (onSelect) onSelect(pinGroup.pins[0]);
                        if (pinGroup.pins[0].onSelect)
                          pinGroup.pins[0].onSelect();
                      }
                    : (event) => {
                        event.stopPropagation();
                        if (!(pinGroup.around ?? around))
                          setExpanded(groupIndex);
                      }
                }
                onMouseEnter={() => {
                  setRotate(false);
                  if (
                    pinGroup.pins.length !== 1 &&
                    !(pinGroup.around ?? around)
                  )
                    setExpanded(groupIndex);
                }}
                onMouseLeave={() => {
                  setRotate(true);
                  setExpanded(null);
                }}
              >
                {pinGroup.pins.length === 1 ? (
                  <div
                    className="single pin"
                    style={{
                      color: colorHexFromNumber(pinGroup.pins[0].color),
                    }}
                  >
                    {pinGroup.pins[0].pin ?? pinGroup.pin ?? pin}
                    <div className="text-container">
                      <h3>{pinGroup.title}</h3>
                      <h4>{pinGroup.pins[0].title}</h4>
                    </div>
                  </div>
                ) : pinGroup.around ?? around ? (
                  <div className="pins-container around">
                    {pinGroup.pins.map((pinElement, elementIndex) => {
                      const theta = roundGap * elementIndex + roundThetaOffset;
                      const radius = pinGroup.multipleGap ?? multipleGap;

                      return (
                        <div key={elementIndex} className="pin-container">
                          <button
                            className="pin"
                            style={{
                              color: colorHexFromNumber(pinElement.color),
                              top: `${radius * Math.cos(theta) * -1}px`,
                              left: `${radius * Math.sin(theta)}px`,
                              transform: `translate(-${
                                pinGroup.stickX ?? stickX
                              }%, -${pinGroup.stickY ?? stickY}%)`,
                            }}
                            onClick={() => {
                              if (onSelect) onSelect(pinElement);
                              if (pinElement.onSelect) pinElement.onSelect();
                            }}
                          >
                            {pinElement.pin ?? pinGroup.pin ?? pin}
                          </button>
                          <div
                            className="text-container"
                            style={{
                              bottom: `calc(${radius}px * -1 - var(--multiple-gap-padding))`,
                              color: colorHexFromNumber(pinElement.color),
                            }}
                          >
                            <h3>{pinGroup.title}</h3>
                            <h4>{pinElement.title}</h4>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="pins-container stacked">
                    {pinGroup.pins.map((pinElement, elementIndex) => (
                      <button
                        key={elementIndex}
                        className="pin"
                        style={{
                          color: colorHexFromNumber(pinElement.color),
                          zIndex: pinGroup.pins.length - elementIndex + 2,
                          left: `calc(${elementIndex} * ${
                            pinGroup.multipleGap ?? multipleGap
                          }px`,
                          transform:
                            groupIndex !== expanded
                              ? `translate(-${pinGroup.stickX ?? stickX}%, -${
                                  pinGroup.stickY ?? stickY
                                }%)`
                              : "none",
                        }}
                        onClick={(event) => {
                          if (groupIndex === expanded) {
                            event.stopPropagation();
                            if (onSelect) onSelect(pinElement);
                            if (pinElement.onSelect) pinElement.onSelect();
                          }
                        }}
                      >
                        {pinElement.pin ?? pinGroup.pin ?? pin}
                        {elementIndex === 0 && groupIndex !== expanded && (
                          <div className="text-container">
                            <h3>{pinGroup.title}</h3>
                          </div>
                        )}
                        {groupIndex === expanded && (
                          <div className="text-container">
                            <h3>{pinGroup.title}</h3>
                            <h4>{pinElement.title}</h4>
                          </div>
                        )}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            )
          );
        })}
      </>
    </div>
  ) : (
    <section id="mobile-version">
      {pins.map(
        (pinGroup, groupIndex) =>
          pinGroup.pins.length > 0 && (
            <div
              onClick={() => {
                if (pinGroup.pins.length > 1)
                  setExpanded(groupIndex === expanded ? null : groupIndex);
                else {
                  if (onSelect) onSelect(pinGroup.pins[0]);
                  if (pinGroup.pins[0].onSelect) pinGroup.pins[0].onSelect();
                }
              }}
              key={groupIndex}
              className="mobile-pin-group"
            >
              <div className="mobile-pin-header">
                <h3 className="title">
                  {pinGroup.title +
                    (pinGroup.pins.length <= 1 &&
                    pinGroup.pins[0].title.length !== 0
                      ? ` (${pinGroup.pins[0].title})`
                      : "")}
                </h3>
                <FontAwesomeIcon
                  icon={
                    pinGroup.pins.length > 1
                      ? expanded === groupIndex
                        ? faChevronUp
                        : faChevronDown
                      : faChevronRight
                  }
                  color={
                    expanded === groupIndex
                      ? "var(--color-fasmr-blue)"
                      : "var(--color-dark)"
                  }
                  className="icon"
                />
              </div>
              {expanded === groupIndex &&
                pinGroup.pins.map((pin, pinIndex) => (
                  <div
                    key={pinIndex}
                    className="mobile-pin"
                    onClick={() => {
                      if (onSelect) onSelect(pin);
                      if (pin.onSelect) pin.onSelect();
                    }}
                  >
                    <h4>{pin.title}</h4>
                  </div>
                ))}
            </div>
          )
      )}
    </section>
  );
}

export default PinMap;
