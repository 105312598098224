import React, { useRef } from "react";
import { useLoaderData, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../generic-error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ResourceDisplay from "../../../components/resource-display";
import ResourceService from "../../../service/resource";
import OutputResource from "../../../dtos/resource";
import ButtonExchanges, {
  ExchangesType,
} from "../../../components/button-exchanges";
import { Link } from "react-router-dom";
import { path as exchangesPath } from "../page-exchanges";
import { path as OpportunitiesPath } from "../index";
import { path as TransmedPath } from "../page-transmed";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

export const path = "/opportunities/";

const resourceKeys = {
  oportunitatiTitle: "opportunities-index-page-title",
  oportunitatiSubtitle: "opportunities-index-page-subtitle",
  transmedTitle: "opportunities-index-section-transmed-title",
  transmedSubtitle: "opportunities-index-section-transmed-subtitle",
  transmedButton: "opportunities-index-section-transmed-button",
  transmedMap: "opportunities-index-section-transmed-map",
  locationPin: "opportunities-index-section-exchanges-location-pin",
  scope: "opportunities-index-section-exchanges-scope",
  score: "opportunities-index-section-exchanges-score",
  exchangesSubtitle: "opportunities-index-section-exchanges-subtitle",
  worldMap: "opportunities-index-section-exchanges-world-map",
  laptop: "opportunities-index-page-cover",
};

type LoaderType = [{ [key: string]: OutputResource }];

export async function loader(): Promise<LoaderType> {
  return [await new ResourceService().read.byIds(Object.values(resourceKeys))];
}

export function Component() {
  const [resources] = useLoaderData() as LoaderType;
  const midPageRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    midPageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="entire-page">
        <div className="upper-page">
          <div className="content-container">
            <div className="titlu">
              <h1>
                <ResourceDisplay
                  resource={resources[resourceKeys.oportunitatiTitle]}
                />
              </h1>
            </div>
            <div className="subtitle">
              <ResourceDisplay
                resource={resources[resourceKeys.oportunitatiSubtitle]}
              />
            </div>
          </div>
          <div className="background1"></div>
          <div className="background2">
            <ResourceDisplay resource={resources[resourceKeys.laptop]} />
          </div>
          <div className="button" onClick={scrollToBottom}>
            <FontAwesomeIcon icon={faAngleDown} size="4x" />
          </div>
        </div>
        <div className="mid-page transmed opportunity-section" ref={midPageRef}>
          <div className="color-container"></div>
          <div className="big-container">
            <div className="pointer">
              <ResourceDisplay resource={resources[resourceKeys.locationPin]} />
            </div>
            <div className="main-container">
              <div className="left-container">
                <div className="title">
                  <h1>
                    <ResourceDisplay
                      resource={resources[resourceKeys.transmedTitle]}
                    />
                  </h1>
                </div>
                <div className="text-container">
                  <ResourceDisplay
                    resource={resources[resourceKeys.transmedSubtitle]}
                  />
                </div>

                <div className="button">
                  <Link
                    to={OpportunitiesPath + TransmedPath}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <ResourceDisplay
                      resource={resources[resourceKeys.transmedButton]}
                    />
                  </Link>
                </div>
              </div>
              <div className="right-container">
                <ResourceDisplay
                  resource={resources[resourceKeys.transmedMap]}
                />
                {/* ceva */}
              </div>
            </div>
          </div>
        </div>
        <div className="lower-page scope-score opportunity-section">
          <div className="color-container"></div>
          <div className="background">
            <ResourceDisplay resource={resources[resourceKeys.worldMap]} />
          </div>
          <div className="text-container">
            <div className="title">
              <ResourceDisplay resource={resources[resourceKeys.scope]} />
              <div className="litera"> & </div>
              <ResourceDisplay resource={resources[resourceKeys.score]} />
            </div>
            <div className="subtitle content-container">
              <ResourceDisplay
                resource={resources[resourceKeys.exchangesSubtitle]}
              />
            </div>
          </div>
          <div className="stretcher" />
          <div className="card-container">
            <div className="card-outgoing">
              <ButtonExchanges type={ExchangesType.out} />
            </div>

            <div className="button">
              <Link
                to={OpportunitiesPath + exchangesPath}
                style={{ textDecoration: "none", color: "white" }}
              >
                <ResourceDisplay
                  resource={resources[resourceKeys.transmedButton]}
                />
              </Link>
            </div>

            <div className="card-incoming">
              <ButtonExchanges type={ExchangesType.in} />
            </div>
          </div>
          <div className="card-container-2">
            <div className="card-outgoing"></div>

            <div className="button">
              <Link
                to={OpportunitiesPath + exchangesPath}
                style={{ textDecoration: "none", color: "white" }}
              >
                <ResourceDisplay
                  resource={resources[resourceKeys.transmedButton]}
                />
              </Link>
            </div>

            <div className="stretcher" />

            <Swiper navigation={true} modules={[Navigation]}>
              <SwiperSlide>
                <ButtonExchanges type={ExchangesType.out} />
              </SwiperSlide>
              <SwiperSlide>
                <ButtonExchanges type={ExchangesType.in} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="stretcher bottom" />
          <div className="color-container"></div>
        </div>
      </div>
    </>
  );
}
Component.displayName = "page-oportunitati";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-error";
