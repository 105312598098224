import ExchangesOutApi from "../api/exchanges_out";
import {
  OutputExchangesContinentDetail,
  OutputExchangesContinentList,
  OutputExchangesOutReview,
  RequestContinentByName,
  RequestContinents,
  RequestOutReviewType,
  RequestOutReviewsByCountry,
} from "../dtos/exchanges_out";
import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from ".";

class ExchangesOutServiceReader extends ServiceReader {
  constructor(protected api: ExchangesOutApi) {
    super();
  }

  public async continents(): Promise<OutputExchangesContinentList[]> {
    const raw = await this.api.get(new RequestContinents());

    if (!Array.isArray(raw))
      throw new TypeError("Continents list is not an array.");

    return raw.map((value) => OutputExchangesContinentList.convert(value));
  }

  public async byContinent(
    continentName: string
  ): Promise<OutputExchangesContinentDetail> {
    return OutputExchangesContinentDetail.convert(
      await this.api.get(new RequestContinentByName(continentName))
    );
  }

  public async byCountry(
    countryName: string,
    type: RequestOutReviewType
  ): Promise<OutputExchangesOutReview[]> {
    const raw = await this.api.get(
      new RequestOutReviewsByCountry(countryName, type)
    );

    if (!Array.isArray(raw))
      throw new TypeError("Reviews list is not an array.");

    return raw.map((value) => OutputExchangesOutReview.convert(value));
  }
}

class ExchangesOutServiceCreator extends ServiceCreator {}
class ExchangesOutServiceUpdater extends ServiceUpdater {}
class ExchangesOutServiceDeleter extends ServiceDeleter {}

export default class ExchangesOutService extends Service {
  constructor() {
    super(new ExchangesOutApi());
  }

  public get read(): ExchangesOutServiceReader {
    return new ExchangesOutServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new ExchangesOutServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new ExchangesOutServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new ExchangesOutServiceDeleter();
  }
}
