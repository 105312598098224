import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faXmark,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import MenuButton from "./menu-button";
import fasmrLogo from "../../images/logos/fasmr-right-title-blue.svg";
import backgroundElement from "../../images/brain-cpu-wires-white.svg";
import RightButton from "./right-button";
import NavigationButton from "./navigation-button";
import { Link } from "react-router-dom";
import NavigationMenu from "./navigation-menu";
import { path as DespreNoiPath } from "../../pages/pages-despre-noi";
import { path as NoutatiPath } from "../../pages/pages-noutati/index.jsx";
import { path as OportunitatiPath } from "../../pages/pages-oportunitati";
import { path as ResursePath } from "../../pages/page-resurse";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * The navigation bar of the website. This should be displayed on all pages.
 *
 * @returns The react component.
 */
function NavigationBar({ news, style, className, ...props }) {
  const [menuActiveState, setMenuActiveState] = useState(false);
  const location = useLocation();
  useEffect(() => setMenuActiveState(false), [location]);

  const styles = {
    nav: {
      "--button-height": "calc(var(--nav-height) - var(--gap-small))",

      position: "fixed",
      top: 0,
      width: "100%",
      height: "var(--nav-height)",
      paddingBottom: "calc(2 * var(--gap-small))",
      zIndex: 5,

      filter: "drop-shadow(var(--drop-shadow-small))",
    },
    bar: {
      position: "relative",
      width: "100%",
      height: "calc(100% - 2 * var(--gap-small))",

      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden",

      paddingTop: "var(--gap-small)",
      paddingBottom: "var(--gap-small)",
      // marginTop: hidden && "calc(-1 * var(--nav-height) - 4px)",

      backgroundColor: "var(--color-gray)",
    },
    backgroundElements: {
      container: {
        position: "absolute",
        zIndex: "0",
        top: "calc(-200% - var(--gap-small))",
        left: "40px",
        height: "300%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        opacity: 0.5,
      },
      first: { height: "120%" },
      second: {
        height: "120%",
        transform: "rotate(180deg)",
        position: "relative",
        bottom: "var(--gap-small)",
      },
      last: {
        height: "120%",
        transform: "rotate(180deg)",
        position: "relative",
        top: "80%",
        left: "80%",
      },
    },
    side: {
      left: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%",
      },
      right: {
        zIndex: "1",
        flexDDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%",
      },
    },
    links: {
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    logo: {
      height: "100%",

      flexDirection: "row",
      alignItems: "center",

      padding: 0,
      border: "none",
    },
  };
  return (
    <div
      style={{ ...styles.nav, ...style }}
      className={"navigation-bar " + className}
      onMouseLeave={() => setMenuActiveState(false)}
      {...props}
    >
      <nav style={styles.bar}>
        <div style={styles.backgroundElements.container}>
          <div style={styles.backgroundElements.first}>
            <img src={backgroundElement} alt="" className="h" />
          </div>
          <div style={styles.backgroundElements.second}>
            <img src={backgroundElement} alt="" className="h" />
          </div>
          <div style={styles.backgroundElements.last}>
            <img src={backgroundElement} alt="" className="h" />
          </div>
        </div>
        <div style={styles.side.left}>
          <MenuButton onClick={() => setMenuActiveState(!menuActiveState)}>
            Meniu
            <div style={{ width: "17px" }}>
              <FontAwesomeIcon
                icon={menuActiveState ? faXmark : faChevronDown}
              />
            </div>
          </MenuButton>
        </div>
        <div style={{ ...styles.logo, flexGrow: 3 }} className="logo">
          <Link to={""} className="inflate">
            <img src={fasmrLogo} alt="FASMR Logo" className="h" />
          </Link>
        </div>
        <div className="stretcher min-1150px" style={{ flexGrow: 6 }} />
        <div style={styles.links} className="min-1150px">
          <NavigationButton href={DespreNoiPath}>Despre noi</NavigationButton>
          <NavigationButton href={NoutatiPath}>Noutăți</NavigationButton>
          <NavigationButton href={OportunitatiPath}>
            Oportunități
          </NavigationButton>
          <NavigationButton href={ResursePath}>Resurse</NavigationButton>
        </div>
        <div className="stretcher min-1150px" style={{ flexGrow: 2 }} />

        <div style={styles.side.right} className="side-right">
          <RightButton>
            <FontAwesomeIcon icon={faHandshake} />
            Susține-ne
          </RightButton>
        </div>
      </nav>

      <NavigationMenu active={menuActiveState} news={news} />
    </div>
  );
}

export default NavigationBar;
