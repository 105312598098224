import OutputEntity, { DtoConversionError, RequestEntity } from "./index";

export class OutputExchangesCountry extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly code: string,
    public readonly scope: boolean,
    public readonly score: boolean,
    public readonly location_x: number,
    public readonly location_y: number
  ) {
    super(id);
  }

  static convert(input: any): OutputExchangesCountry {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const title = input.title ?? undefined;
    const code = input.code ?? undefined;
    const scope = input.scope ?? undefined;
    const score = input.score ?? undefined;
    const location_x = input.location_x ?? undefined;
    const location_y = input.location_y ?? undefined;

    if (typeof id !== "number")
      throw new OutputExchangesCountryConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputExchangesCountryConversionError("name", input);
    if (typeof title !== "string")
      throw new OutputExchangesCountryConversionError("title", input);
    if (typeof code !== "string")
      throw new OutputExchangesCountryConversionError("code", input);
    if (typeof scope !== "boolean")
      throw new OutputExchangesCountryConversionError("scope", input);
    if (typeof score !== "boolean")
      throw new OutputExchangesCountryConversionError("score", input);
    if (typeof location_x !== "number")
      throw new OutputExchangesCountryConversionError("location_x", input);
    if (typeof location_y !== "number")
      throw new OutputExchangesCountryConversionError("location_y", input);

    return OutputExchangesCountryConversionError.propagate(
      OutputExchangesCountryConversionError.dtoName,
      () =>
        new OutputExchangesCountry(
          id,
          name,
          title,
          code,
          scope,
          score,
          location_x,
          location_y
        )
    );
  }
}

class OutputExchangesCountryConversionError extends DtoConversionError {
  public static dtoName: string = "OutputExchangesCountry";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputExchangesCountryConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class OutputExchangesContinentList extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly location_x: number,
    public readonly location_y: number,
    public readonly scopes: number,
    public readonly scores: number
  ) {
    super(id);
  }

  static convert(input: any): OutputExchangesContinentList {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const title = input.title ?? undefined;
    const location_x = input.location_x ?? undefined;
    const location_y = input.location_y ?? undefined;
    const scopes = input.scopes ?? undefined;
    const scores = input.scores ?? undefined;

    if (typeof id !== "number")
      throw new OutputExchangesContinentListConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputExchangesContinentListConversionError("name", input);
    if (typeof title !== "string")
      throw new OutputExchangesContinentListConversionError("title", input);
    if (typeof location_x !== "number")
      throw new OutputExchangesContinentListConversionError(
        "location_x",
        input
      );
    if (typeof location_y !== "number")
      throw new OutputExchangesContinentListConversionError(
        "location_y",
        input
      );
    if (typeof scopes !== "number")
      throw new OutputExchangesContinentListConversionError("scopes", input);
    if (typeof scores !== "number")
      throw new OutputExchangesContinentListConversionError("scores", input);

    return OutputExchangesContinentListConversionError.propagate(
      OutputExchangesContinentListConversionError.dtoName,
      () =>
        new OutputExchangesContinentList(
          id,
          name,
          title,
          location_x,
          location_y,
          scopes,
          scores
        )
    );
  }
}

class OutputExchangesContinentListConversionError extends DtoConversionError {
  public static dtoName: string = "OutputExchangesContinentList";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputExchangesContinentListConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class OutputExchangesContinentDetail extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly image_scope: string,
    public readonly image_score: string,
    public readonly countries: OutputExchangesCountry[]
  ) {
    super(id);
  }

  static convert(input: any): OutputExchangesContinentDetail {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const title = input.title ?? undefined;
    const image_scope = input.image_scope ?? undefined;
    const image_score = input.image_score ?? undefined;
    const countries = input.countries ?? undefined;

    if (typeof id !== "number")
      throw new OutputExchangesContinentDetailConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputExchangesContinentDetailConversionError("name", input);
    if (typeof title !== "string")
      throw new OutputExchangesContinentDetailConversionError("title", input);
    if (typeof image_scope !== "string")
      throw new OutputExchangesContinentDetailConversionError(
        "image_scope",
        input
      );
    if (typeof image_score !== "string")
      throw new OutputExchangesContinentDetailConversionError(
        "image_score",
        input
      );
    if (!Array.isArray(countries))
      throw new OutputExchangesContinentDetailConversionError(
        "countries",
        input
      );

    return OutputExchangesContinentDetailConversionError.propagate(
      OutputExchangesContinentDetailConversionError.dtoName,
      () =>
        new OutputExchangesContinentDetail(
          id,
          name,
          title,
          image_scope,
          image_score,
          countries.map((country: any) =>
            OutputExchangesCountry.convert(country)
          )
        )
    );
  }
}

class OutputExchangesContinentDetailConversionError extends DtoConversionError {
  public static dtoName: string = "OutputExchangesContinentDetail";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputExchangesContinentDetailConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class OutputExchangesOutReview extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly city: string,
    public readonly title: string,
    public readonly pros: string,
    public readonly cons: string | undefined,
    public readonly date: Date
  ) {
    super(id);
  }

  static convert(input: any): OutputExchangesOutReview {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const city = input.city ?? undefined;
    const title = input.title ?? undefined;
    const pros = input.pros ?? undefined;
    const cons = input.cons ?? undefined;
    const date = input.date ?? undefined;

    if (typeof id !== "number")
      throw new OutputExchangesOutReviewConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputExchangesOutReviewConversionError("name", input);
    if (typeof city !== "string")
      throw new OutputExchangesOutReviewConversionError("city", input);
    if (typeof title !== "string")
      throw new OutputExchangesOutReviewConversionError("title", input);
    if (typeof pros !== "string")
      throw new OutputExchangesOutReviewConversionError("pros", input);
    if (typeof cons !== "string" && cons !== undefined)
      throw new OutputExchangesOutReviewConversionError("cons", input);
    if (typeof date !== "string")
      throw new OutputExchangesOutReviewConversionError("date", input);

    return OutputExchangesOutReviewConversionError.propagate(
      OutputExchangesOutReviewConversionError.dtoName,
      () =>
        new OutputExchangesOutReview(
          id,
          name,
          city,
          title,
          pros,
          cons,
          new Date(date)
        )
    );
  }
}

class OutputExchangesOutReviewConversionError extends DtoConversionError {
  public static dtoName: string = "OutputExchangesOutReview";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputExchangesOutReviewConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class RequestContinents extends RequestEntity {
  public readonly continents = null;
}

export class RequestContinentByName extends RequestEntity {
  constructor(public readonly continent: string) {
    super();
  }
}

export enum RequestOutReviewType {
  "scope" = "scope",
  "score" = "score",
}

export class RequestOutReviewsByCountry extends RequestEntity {
  public readonly reviews = null;
  constructor(
    public readonly country: string,
    public readonly type: RequestOutReviewType
  ) {
    super();
  }
}
