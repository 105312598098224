import "./styles.css";
import "swiper/css";
import "swiper/css/effect-cards";
import ResourceDisplay from "../../../../components/resource-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CardStructure from "../../../../components/card-structure/index";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";

import { path as asociatiiPath } from "../../page-asociatii/index.jsx";
import { Link } from "react-router-dom";

export const id = "asociatii";
function SectionAsociatii({ title, text, members }) {
  const [fullTitle, fullList, obsTitle, obsList] = members;
  return (
    <div id={id} className="container-wrapper">
      <div className="container content-container">
        <div className="information">
          <ResourceDisplay resource={title} wrapper="h2" />
          <ResourceDisplay resource={text} className="text" />
          <Link to={asociatiiPath} className="fill-secondary-button w-gap">
            <span>Află mai multe</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
        {fullList.length > 0 && (
          <div className="cards-container">
            <ResourceDisplay resource={fullTitle} wrapper="h3" />
            <div className="cards-grid">
              {fullList?.map((memeber) => (
                <CardStructure
                  structure={memeber}
                  inverse
                  imageBackgroundColor="var(--color-light)"
                  textBackgroundColor="white"
                  textColor="var(--color-dark)"
                  textWrapper="div"
                  className="card"
                />
              ))}
            </div>
            <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
              {fullList?.map((memeber) => (
                <SwiperSlide>
                  <CardStructure
                    structure={memeber}
                    inverse
                    imageBackgroundColor="var(--color-light)"
                    textBackgroundColor="white"
                    textColor="var(--color-dark)"
                    textWrapper="div"
                    className="card"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {obsList.length > 0 && (
          <div className="cards-container">
            <ResourceDisplay resource={obsTitle} wrapper="h3" />
            <div className="cards-grid">
              {obsList?.map((memeber) => (
                <CardStructure
                  structure={memeber}
                  inverse
                  imageBackgroundColor="var(--color-light)"
                  textBackgroundColor="white"
                  textColor="var(--color-dark)"
                  textWrapper="div"
                  className="card"
                />
              ))}
            </div>
            <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
              {obsList?.map((memeber) => (
                <SwiperSlide>
                  <CardStructure
                    structure={memeber}
                    inverse
                    imageBackgroundColor="var(--color-light)"
                    textBackgroundColor="white"
                    textColor="var(--color-dark)"
                    textWrapper="div"
                    className="card"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
}

export default SectionAsociatii;
