import { RequestGalleryEntriesByCounty } from "../dtos/transmed/gallery";
import Api from "./index";

export default class TransmedGalleryApi extends Api {
  constructor() {
    super("/transmed_gallery.php");
  }

  public get(params: RequestGalleryEntriesByCounty): Promise<any> {
    return super.get(params);
  }
}
