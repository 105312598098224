import { Outlet, Route, useLoaderData, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../generic-error";
import ResourceService from "../../../service/resource";
import OutputResource from "../../../dtos/resource";
import ResourceDisplay from "../../../components/resource-display";
import * as PageIndex from "./default/index";
import * as PageIn from "./incomings/index";
import * as PageOut from "./outgoings/index";
import ButtonExchanges, {
  ExchangesType,
} from "../../../components/button-exchanges";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import OutputExchangesCard from "../../../dtos/exchanges/card";
import ExchangesCardService from "../../../service/exchanges/card";
import { IMAGE_HOST } from "../../../api/config";

export const path = "exchanges/";

const resourceKeys = {
  headerWide: "opportunities-exchanges-header-wide",
  planeLeft: "opportunities-exchanges-plane-left",
  lane: "opportunities-lane",
};

type LoaderType = [{ [key: string]: OutputResource }, OutputExchangesCard[]];

export async function loader(): Promise<LoaderType> {
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  const cards = new ExchangesCardService().read.all();
  return [await resources, await cards];
}

export function Component() {
  const [resources, cards] = useLoaderData() as LoaderType;

  return (
    <div id="page-oportunitati-exchanges">
      <header>
        <ResourceDisplay resource={resources[resourceKeys.headerWide]} />
      </header>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="airplane-swiper"
      >
        {cards.map((card) => (
          <SwiperSlide className="airplane-slide">
            <div className="mid-container">
              <div className="upper-container">
                <div className="air left">
                  <ResourceDisplay
                    resource={resources[resourceKeys.lane]}
                    className="lane"
                  />
                  <div className="airplane">
                    <ResourceDisplay
                      resource={resources[resourceKeys.planeLeft]}
                    />
                  </div>
                </div>
                <div className="title-container">
                  <h3 className="title">{card.title}</h3>
                  {/* <div className="button">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div> */}
                </div>
                <div className="air">
                  <ResourceDisplay
                    resource={resources[resourceKeys.lane]}
                    className="lane"
                  />
                </div>
              </div>
              <div className="lower-container content-container">
                <div className="subtitle">{card.subtitle}</div>
                {card.image && (
                  <img src={IMAGE_HOST + card.image} alt={card.image} />
                )}
                {/* <div className="line"></div> */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <section className="content-container">
        <div className="account">Account</div>
      </section> */}
      <section className="reviews">
        <div className="buttons">
          <ButtonExchanges type={ExchangesType.out} />
          <ButtonExchanges type={ExchangesType.in} />
        </div>
        <Outlet />
      </section>
    </div>
  );
}
Component.displayName = "page-oportunitati-exchanges";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-exchanges-error";

export const children = [
  <Route index {...PageIndex} />,
  <Route {...PageIn} />,
  <Route {...PageOut} />,
];
