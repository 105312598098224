import GalleryApi from "../api/gallery";
import OutputGalleryEntry, {
  RequestGalleryEntriesByStructure,
} from "../dtos/gallery";
import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from ".";

class GalleryServiceReader extends ServiceReader {
  constructor(protected api: GalleryApi) {
    super();
  }

  public async byStructure(strucutreId: string): Promise<OutputGalleryEntry[]> {
    const raw = await this.api.get(new RequestGalleryEntriesByStructure(strucutreId));
    if (!Array.isArray(raw))
      throw new TypeError("Gallery entry list is not an array.");

    return raw.map((value) => OutputGalleryEntry.convert(value));
  }
}

class GalleryServiceCreator extends ServiceCreator {}
class GalleryServiceUpdater extends ServiceUpdater {}
class GalleryServiceDeleter extends ServiceDeleter {}

export default class GalleryService extends Service {
  constructor() {
    super(new GalleryApi());
  }

  public get read(): GalleryServiceReader {
    return new GalleryServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new GalleryServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new GalleryServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new GalleryServiceDeleter();
  }
}
