import OutputEntity, { DtoConversionError, RequestEntity } from "..";

export class OutputTransmedCounty extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly location_x: number,
    public readonly location_y: number
  ) {
    super(id);
  }

  static convert(input: any): OutputTransmedCounty {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const title = input.title ?? undefined;
    const location_x = input.location_x ?? undefined;
    const location_y = input.location_y ?? undefined;

    if (typeof id !== "number")
      throw new OutputTransmedCountyConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputTransmedCountyConversionError("name", input);
    if (typeof title !== "string")
      throw new OutputTransmedCountyConversionError("title", input);
    if (typeof location_x !== "number")
      throw new OutputTransmedCountyConversionError("location_x", input);
    if (typeof location_y !== "number")
      throw new OutputTransmedCountyConversionError("location_y", input);

    return OutputTransmedCountyConversionError.propagate(
      OutputTransmedCountyConversionError.dtoName,
      () => new OutputTransmedCounty(id, name, title, location_x, location_y)
    );
  }
}

class OutputTransmedCountyConversionError extends DtoConversionError {
  public static dtoName: string = "OutputTransmedCounty";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputTransmedCountyConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class RequestCounties extends RequestEntity {
  public readonly counties = null;
}
