import { getGroup } from "../../api/structure/get";
import ServiceError from "../error";
import { IMAGE_HOST } from "../../api/config";
import OutputStructure, { OutputStructureGroup } from "../../dtos/structure";
import { toOutputResource } from "../resource/index";

export function toOutputStructure(structure: any): OutputStructure {
  if (structure.id === undefined || structure.id === null)
    throw new ServiceError("Invalid structure", "Empty id.", structure);

  if (structure.name === undefined || structure.name === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty name for structure ${structure.id}.`,
      structure
    );

  if (structure.name_short === undefined || structure.name_short === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty name_short for structure ${structure.id}.`,
      structure
    );

  if (structure.logo_text === undefined || structure.logo_text === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty logo_text for structure ${structure.id}.`,
      structure
    );
  const logo_text = toOutputResource(structure.logo_text);

  if (
    structure.color === undefined ||
    structure.color === null ||
    structure.color! instanceof Number
  )
    throw new ServiceError(
      "Invalid structure",
      `Empty color for structure ${structure.id}.`,
      structure
    );

  const group =
    OutputStructureGroup[
      String(structure.group) as keyof typeof OutputStructureGroup
    ];
  if (group === undefined || group === null)
    throw new ServiceError(
      "Invalid structure",
      `Empty group for structure ${structure.id}.`,
      structure
    );

  return new OutputStructure(
    String(structure.id),
    String(structure.name),
    String(structure.name_short),
    structure.url,
    structure.logo_simple_white,
    structure.logo_simple_color,
    IMAGE_HOST,
    logo_text,
    structure.color,
    group
  );
}

export async function getStructureGroup(
  group: string
): Promise<OutputStructure[]> {
  const rawData: any = await getGroup(group);
  if (!Array.isArray(rawData))
    throw new ServiceError(
      "Invalid structure array",
      "Invalid structure array.",
      rawData
    );

  return rawData.map((structure) => toOutputStructure(structure));
}
