import OutputEntity from "./index";
import OutputResource from "./resource";

export class OutputNewsSummary extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly content: string,
    public readonly date: Date,
    public readonly front_img: OutputResource,
    public readonly hidden: boolean = false
  ) {
    super(id);
  }
}

class OutputNewsDetail extends OutputNewsSummary {
  constructor(
    id: number,
    name: string,
    title: string,
    content: string,
    date: Date,
    front_img: OutputResource,
    hidden: boolean = false,
    public readonly back_img: OutputResource,
    public readonly facebook: string,
    public readonly instagram: string,
    public readonly hashtags: string[],
    public readonly related: OutputNewsSummary[]
  ) {
    super(id, name, title, content, date, front_img, hidden);
  }
}
export default OutputNewsDetail;
