export default class OutputEntity {
  constructor(public readonly id: any) {}
  static convert(input: any): OutputEntity {
    if (input.id === undefined || input.id === null)
      throw new OutputEntityConversionError("id", input);
    return new OutputEntity(input.id);
  }
}

export class InputEntity {}

export class RequestEntity extends InputEntity {}

export class DtoConversionError extends Error {
  constructor(
    public dtoName: string,
    public propertyName: string,
    public rawData: any,
    public hint?: string
  ) {
    super(
      `DtoConversionError (${dtoName}): Invalid ${propertyName} \`${rawData[propertyName]}\`` +
        (hint !== undefined && hint !== null ? `: ${hint}` : "")
    );
  }

  static stack(
    dtoName: string,
    previous: DtoConversionError
  ): DtoConversionError {
    return new DtoConversionError(
      dtoName + previous.dtoName,
      previous.propertyName,
      previous.rawData,
      previous.hint
    );
  }

  static propagate<T>(dtoName: string, callback: () => T): T {
    try {
      return callback();
    } catch (error) {
      if (error instanceof DtoConversionError)
        throw DtoConversionError.stack(dtoName, error);
      else throw error;
    }
  }
}

class OutputEntityConversionError extends DtoConversionError {
  public static dtoName: string = "OutputEntity";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(OutputEntityConversionError.dtoName, propertyName, rawData, hint);
  }
}

export function enumConvert<T extends object>(
  enummeration: T,
  key: any
): T[keyof T] {
  if (key in enummeration) {
    return enummeration[key as keyof T];
  } else {
    throw new DtoConversionError(enummeration.toString(), "key", {
      key: key,
    });
  }
}
