import {
  RequestCounties,
  RequestInReviewsByCounty,
} from "../dtos/exchanges_in";
import Api from "./index";

export default class ExchangesInApi extends Api {
  constructor() {
    super("/exchanges_in.php");
  }

  public get(params: RequestCounties | RequestInReviewsByCounty): Promise<any> {
    return super.get(params);
  }
}
