import TransmedCardApi from "../../api/transmed_card";
import OutputTransmedCard from "../../dtos/transmed/card";
import Service, {
  ServiceCreator,
  ServiceDeleter,
  ServiceReader,
  ServiceUpdater,
} from "..";

class TransmedCardServiceReader extends ServiceReader {
  constructor(protected api: TransmedCardApi) {
    super();
  }

  public async all(): Promise<OutputTransmedCard[]> {
    const raw = await this.api.get();
    if (!Array.isArray(raw))
      throw new TypeError("TransmedCard entry list is not an array.");

    return raw.map((value) => OutputTransmedCard.convert(value));
  }
}

class TransmedCardServiceCreator extends ServiceCreator {}
class TransmedCardServiceUpdater extends ServiceUpdater {}
class TransmedCardServiceDeleter extends ServiceDeleter {}

export default class TransmedCardService extends Service {
  constructor() {
    super(new TransmedCardApi());
  }

  public get read(): TransmedCardServiceReader {
    return new TransmedCardServiceReader(this.api);
  }
  public get create(): ServiceCreator {
    return new TransmedCardServiceCreator();
  }
  public get update(): ServiceUpdater {
    return new TransmedCardServiceUpdater();
  }
  public get delete(): ServiceDeleter {
    return new TransmedCardServiceDeleter();
  }
}
