import { useLoaderData, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../../../generic-error";
import ResourceDisplay from "../../../../../../components/resource-display";
import OutputResource from "../../../../../../dtos/resource";
import ResourceService from "../../../../../../service/resource";

const resourceKeys = {
  aboutLinie: "about-nonews-liniealbastra",
  aboutPeople: "about-nonews-people",
};

export async function loader(): Promise<{ [key: string]: OutputResource }> {
  return await new ResourceService().read.byIds(Object.values(resourceKeys));
}

export function Component() {
  const resources = useLoaderData() as { [key: string]: OutputResource };

  return (
    <div className="content-container">
      <div className="no-reviews">
        <div className="big-container">
          <h2 className="text-container">Select a country</h2>
          <div className="line-container">
            <ResourceDisplay resource={resources[resourceKeys.aboutLinie]} />
          </div>
        </div>
        <div className="image-container">
          <ResourceDisplay resource={resources[resourceKeys.aboutPeople]} />
        </div>
      </div>
    </div>
  );
}
Component.displayName = "page-oportunitati-exchanges-outgoings-continent-index";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName =
  "page-oportunitati-exchanges-outgoings-continent-index-error";
