import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import PageLayout, { loader, MainErrorBoundary } from "./page-layout";
import * as Index from "./page-index";
import * as Noutati from "./pages-noutati";
import * as Oportunitati from "./pages-oportunitati"; 
import * as Resurse from "./page-resurse";
import * as DespreNoi from "./pages-despre-noi";
import * as Sustine from "./page-sustine";
import * as ParteneriSponsori from "./page-parteneri-sponsori";
import * as Contact from "./page-contact";
import * as Redirect from "./page-redirect";
import * as NotFound from "./page-not-found";
import * as InConstruction from "./page-in-constructie";

/**
 * This is the main router of the website and holds information about the page hierarchy.
 *
 * @returns A react component representing the global page router
 */
function Pages() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        Component={PageLayout}
        loader={loader}
        ErrorBoundary={MainErrorBoundary}
      >
        <Route index {...Index} />
        <Route {...Noutati} />
        <Route {...Oportunitati} />
        <Route {...Resurse} />
        <Route {...DespreNoi} />
        <Route {...Sustine} />
        <Route {...ParteneriSponsori} />
        <Route {...Contact} />
        <Route {...Redirect} />
        <Route {...InConstruction} />
        <Route path="*" {...NotFound} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default Pages;
