import "./styles.css";
import { useLoaderData, useRouteError } from "react-router-dom";
import { getResources } from "../../../service/resource/index";
import { getPersonGroup } from "../../../service/person/index";
import Profile from "../../../components/membru-cd";
import ResourceDisplay from "../../../components/resource-display";
import { useEffect, useState } from "react";
import GenericErrorDisplay from "../../generic-error";

export const path = "cd/";

const resourceKeys = {
  header: "about-cd-header-oameni",
  text: "about-cd-header-text",
};

export async function loader() {
  const resourcesArray = getResources(Object.values(resourceKeys));
  const cdPeople = getPersonGroup("cd");
  return [await resourcesArray, await cdPeople];
}

export function Component() {
  const [resourcesArray, cdPeople] = useLoaderData();
  const [peopleLocation, setPeopleLocation] = useState(null);
  const [textLocation, setTextLocation] = useState(null);
  useEffect(() => {
    if (
      resourcesArray === undefined ||
      resourcesArray === null ||
      !Array.isArray(resourcesArray)
    ) {
      setPeopleLocation(null);
      return;
    }

    resourcesArray.forEach((resource) => {
      if (resource.id === resourceKeys.header) setPeopleLocation(resource);
      if (resource.id === resourceKeys.text) setTextLocation(resource);
    });
  }, [resourcesArray]);

  return (
    <div id="page-cd">
      <div className="header">
        <div className="title">
          <h2>Consiliul Director</h2>
        </div>
        <div className="content-text">
          <ResourceDisplay
            className="header-text"
            resource={textLocation}
          ></ResourceDisplay>
        </div>
        <div className="image1c">
          <ResourceDisplay
            className="image1"
            resource={peopleLocation}
          ></ResourceDisplay>
        </div>
      </div>
      <div className="body">
        <div className="Membru-CD">
          {cdPeople.map((person, index) => {
            return (
              <Profile
                nume={person.name}
                functie={person.position}
                email={person.email}
                image={person.profileLocation}
                descriere={person.description}
                reverse={index % 2 === 0}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
Component.displayName = "page-about-cd";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-about-cd-error";
